import React from 'react'
import "./checkout.scss" 
import { useState } from 'react'
import { useEffect } from 'react'
import axios from 'axios';
import { useLocation   } from 'react-router-dom';
import { Link, useParams } from 'react-router-dom'
import Mapcheckout from './Mapcheckout';

//console.log("sessional storage " , sessionStorage)
const CheckoutPage = () => {
const graphqlEndpoint = process.env.REACT_APP_GRAPHQL_ENDPOINT;
const location = useLocation();
const { storeId } = useParams();
const searchParams = new URLSearchParams(location.search);


const [shelfIds, setShelfIds] = useState([]);



const selectedShelves = JSON.parse(
  decodeURIComponent(searchParams.get('selectedShelves'))
);
const totalRent1 = decodeURIComponent(searchParams.get('totalRent1'));
const totalRent = decodeURIComponent(searchParams.get('totalRent'));
const itemCount = selectedShelves.length;

//console.log("Number of items in selectedShelves:", itemCount);
	const [storeData, setStoreData] = useState(null);
  
	useEffect(() => {
	  if (storeId) {
		// Fetch all store records using the storeId
		const graphqlQuery = `
		  query {
			getAllStoreRecordsById(Id: "${storeId}") {
			  StoreName,
			  ShopCategory,
			  StoreAddress,
			  AverageOrderValue,
			  stroreImageUrl,
			  selectCity,
			  storeId,
			  PaymentReconciliation,
			  CustomerDemography,
			  SelectedState,
			  StoreType,
			  storeTiming,
			  shelfDetails {
			  shelfName,
			  shelf_id,
			  createdDate,
			  shelfImage_url,
			  shelfRent,
			  }
			}
		  }
		`;
  
		axios.post(graphqlEndpoint, { query: graphqlQuery })
		  .then(response => {
			
			console.log('All Store Data:', response.data.data.getAllStoreRecordsById);
		
			setStoreData(response.data.data.getAllStoreRecordsById);
			if (response.data.data.getAllStoreRecordsById && response.data.data.getAllStoreRecordsById.shelfDetails) {
				response.data.data.getAllStoreRecordsById.shelfDetails.forEach(shelf => {
				  console.log('Shelf ID: print new', shelf.shelf_id);
				  const fetchedShelfIds = response.data.data.getAllStoreRecordsById.shelfDetails.map(shelf => shelf.shelf_id);
				  setShelfIds(fetchedShelfIds);
		
				});
			  }
		  })
		
		  .catch(error => {
			console.error('Error fetching store data:', error);
		  });
	  }
	}, [storeId, graphqlEndpoint]);
	useEffect(() => {
		// Access shelfIds here or wherever needed
		
	  }, [shelfIds]);

	if (!storeData) {
		
		return <div>Loading...</div>;
	  }
	
	const addressMatch = storeData.SelectedState?.match(/"([^"]+)"\s*Latitude\s*:\s*([-+]?\d*\.\d+)\s*Longitude\s*:\s*([-+]?\d*\.\d+)/);
  
	 const StoreName = storeData.StoreName ;

	const address = addressMatch ? addressMatch[1].trim() : null;
	//const latitude = addressMatch ? parseFloat(addressMatch[2]) : null;
	//const longitude = addressMatch ? parseFloat(addressMatch[3]) : null;
	

 console.log(addressMatch)
    const storeimage =  storeData.stroreImageUrl ; 
	const imageUrls = storeimage ? storeimage.split(',') : [];
  
	// Use only the first image URL
	const mainImageUrl1 = imageUrls.length > 0 ? imageUrls[0] : null;
	//const mainImageUrl2 = imageUrls.length > 0 ? imageUrls[1] : null;

// unique id generation


// console.log('Shelf IDs:', shelfIds);

 //boooking handling 
 console.log(totalRent);
 const handleBookingConfirmation = async () => {
    try {
    
      const response = await axios.post(graphqlEndpoint, {
        query: `
          mutation {
            sendBookingConfirmation(
				shelfId : "${shelfIds}" ,
                endDate: "${localStorage.getItem('selectedEndDate')}",
                startDate: "${localStorage.getItem('selectedStartDate')}",
                bookingId: "${sessionStorage.getItem('booking_id')}",
                email: "${sessionStorage.getItem('EcomUserEmail')}" ,
				amount : "${totalRent}" ,
            )
          }
        `,
      });

      // Handle the response as needed
    console.log('Booking confirmation response:', response.data);
    } catch (error) {
      // Handle errors
      console.error('Error sending booking confirmation:', error);
    }
  };

 
 console.log("localstoagra",localStorage)
 console.log("sessional storage" , sessionStorage)



  return (

    		<div className="checkoutbreadcrumbToDetachParent">
      			<div className="checkoutbreadcrumbToDetach1">
				  <Link  to="/" style={{ color: ' #09a7b3 ' }}>
				  <div className="checkoutlink">Home</div>
                   </Link>
        				
        				<div className="checkouticon3">
          					<img className="checkoutchevronRightIcon3" alt="" src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9IkNoZXZyb24gUmlnaHQiPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNOC41MTU1MiA2LjI2NTI4TDQuNzY1NTIgMTAuMDE1M0M0LjczMDY4IDEwLjA1MDEgNC42ODkzMiAxMC4wNzc4IDQuNjQzNzkgMTAuMDk2NkM0LjU5ODI3IDEwLjExNTUgNC41NDk0OCAxMC4xMjUyIDQuNTAwMjEgMTAuMTI1MkM0LjQ1MDk0IDEwLjEyNTIgNC40MDIxNCAxMC4xMTU1IDQuMzU2NjIgMTAuMDk2NkM0LjMxMTEgMTAuMDc3OCA0LjI2OTc0IDEwLjA1MDEgNC4yMzQ5IDEwLjAxNTNDNC4yMDAwNSA5Ljk4MDQ0IDQuMTcyNDIgOS45MzkwNyA0LjE1MzU2IDkuODkzNTVDNC4xMzQ3IDkuODQ4MDMgNC4xMjUgOS43OTkyNCA0LjEyNSA5Ljc0OTk2QzQuMTI1IDkuNzAwNjkgNC4xMzQ3IDkuNjUxOSA0LjE1MzU2IDkuNjA2MzhDNC4xNzI0MiA5LjU2MDg2IDQuMjAwMDUgOS41MTk0OSA0LjIzNDkgOS40ODQ2NUw3LjcyMDA1IDUuOTk5OTZMNC4yMzQ5IDIuNTE1MjhDNC4xNjQ1MyAyLjQ0NDkxIDQuMTI1IDIuMzQ5NDggNC4xMjUgMi4yNDk5NkM0LjEyNSAyLjE1MDQ1IDQuMTY0NTMgMi4wNTUwMiA0LjIzNDkgMS45ODQ2NUM0LjMwNTI2IDEuOTE0MjkgNC40MDA3IDEuODc0NzYgNC41MDAyMSAxLjg3NDc2QzQuNTk5NzIgMS44NzQ3NiA0LjY5NTE2IDEuOTE0MjkgNC43NjU1MiAxLjk4NDY1TDguNTE1NTIgNS43MzQ2NUM4LjU1MDM5IDUuNzY5NDggOC41NzgwNSA1LjgxMDg0IDguNTk2OTIgNS44NTYzNkM4LjYxNTc5IDUuOTAxODkgOC42MjU1IDUuOTUwNjggOC42MjU1IDUuOTk5OTZDOC42MjU1IDYuMDQ5MjUgOC42MTU3OSA2LjA5ODA0IDguNTk2OTIgNi4xNDM1N0M4LjU3ODA1IDYuMTg5MDkgOC41NTAzOSA2LjIzMDQ1IDguNTE1NTIgNi4yNjUyOFoiIGZpbGw9IiMxQTIxMkIiLz4KPC9nPgo8L3N2Zz4K" />
        				</div>
						<Link  to="/Browseshops" style={{ color: ' #09a7b3 ' }}>
				  {storeData && (
        <div className='checkoutlink'>
        Listings in   {storeData.selectCity}
        </div>
      )}
                   </Link>
        		
				
        				<div className="checkouticon3">
          					<img className="checkoutchevronRightIcon3" alt="" src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9IkNoZXZyb24gUmlnaHQiPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNOC41MTU1MiA2LjI2NTI4TDQuNzY1NTIgMTAuMDE1M0M0LjczMDY4IDEwLjA1MDEgNC42ODkzMiAxMC4wNzc4IDQuNjQzNzkgMTAuMDk2NkM0LjU5ODI3IDEwLjExNTUgNC41NDk0OCAxMC4xMjUyIDQuNTAwMjEgMTAuMTI1MkM0LjQ1MDk0IDEwLjEyNTIgNC40MDIxNCAxMC4xMTU1IDQuMzU2NjIgMTAuMDk2NkM0LjMxMTEgMTAuMDc3OCA0LjI2OTc0IDEwLjA1MDEgNC4yMzQ5IDEwLjAxNTNDNC4yMDAwNSA5Ljk4MDQ0IDQuMTcyNDIgOS45MzkwNyA0LjE1MzU2IDkuODkzNTVDNC4xMzQ3IDkuODQ4MDMgNC4xMjUgOS43OTkyNCA0LjEyNSA5Ljc0OTk2QzQuMTI1IDkuNzAwNjkgNC4xMzQ3IDkuNjUxOSA0LjE1MzU2IDkuNjA2MzhDNC4xNzI0MiA5LjU2MDg2IDQuMjAwMDUgOS41MTk0OSA0LjIzNDkgOS40ODQ2NUw3LjcyMDA1IDUuOTk5OTZMNC4yMzQ5IDIuNTE1MjhDNC4xNjQ1MyAyLjQ0NDkxIDQuMTI1IDIuMzQ5NDggNC4xMjUgMi4yNDk5NkM0LjEyNSAyLjE1MDQ1IDQuMTY0NTMgMi4wNTUwMiA0LjIzNDkgMS45ODQ2NUM0LjMwNTI2IDEuOTE0MjkgNC40MDA3IDEuODc0NzYgNC41MDAyMSAxLjg3NDc2QzQuNTk5NzIgMS44NzQ3NiA0LjY5NTE2IDEuOTE0MjkgNC43NjU1MiAxLjk4NDY1TDguNTE1NTIgNS43MzQ2NUM4LjU1MDM5IDUuNzY5NDggOC41NzgwNSA1LjgxMDg0IDguNTk2OTIgNS44NTYzNkM4LjYxNTc5IDUuOTAxODkgOC42MjU1IDUuOTUwNjggOC42MjU1IDUuOTk5OTZDOC42MjU1IDYuMDQ5MjUgOC42MTU3OSA2LjA5ODA0IDguNTk2OTIgNi4xNDM1N0M4LjU3ODA1IDYuMTg5MDkgOC41NTAzOSA2LjIzMDQ1IDguNTE1NTIgNi4yNjUyOFoiIGZpbGw9IiMxQTIxMkIiLz4KPC9nPgo8L3N2Zz4K" />
        				</div>
						<Link  to={`/Browseshops/Sdp/${storeId}`} style={{ color: '#09a7b3' }}>
						{storeData && (
        <div className='checkoutlink'>
          {storeData.StoreName}
        </div>
      )}
                   </Link>
				
				   
        				<div className="checkouticon3">
          					<img className="checkoutchevronRightIcon3" alt="" src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9IkNoZXZyb24gUmlnaHQiPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNOC41MTU1MiA2LjI2NTI4TDQuNzY1NTIgMTAuMDE1M0M0LjczMDY4IDEwLjA1MDEgNC42ODkzMiAxMC4wNzc4IDQuNjQzNzkgMTAuMDk2NkM0LjU5ODI3IDEwLjExNTUgNC41NDk0OCAxMC4xMjUyIDQuNTAwMjEgMTAuMTI1MkM0LjQ1MDk0IDEwLjEyNTIgNC40MDIxNCAxMC4xMTU1IDQuMzU2NjIgMTAuMDk2NkM0LjMxMTEgMTAuMDc3OCA0LjI2OTc0IDEwLjA1MDEgNC4yMzQ5IDEwLjAxNTNDNC4yMDAwNSA5Ljk4MDQ0IDQuMTcyNDIgOS45MzkwNyA0LjE1MzU2IDkuODkzNTVDNC4xMzQ3IDkuODQ4MDMgNC4xMjUgOS43OTkyNCA0LjEyNSA5Ljc0OTk2QzQuMTI1IDkuNzAwNjkgNC4xMzQ3IDkuNjUxOSA0LjE1MzU2IDkuNjA2MzhDNC4xNzI0MiA5LjU2MDg2IDQuMjAwMDUgOS41MTk0OSA0LjIzNDkgOS40ODQ2NUw3LjcyMDA1IDUuOTk5OTZMNC4yMzQ5IDIuNTE1MjhDNC4xNjQ1MyAyLjQ0NDkxIDQuMTI1IDIuMzQ5NDggNC4xMjUgMi4yNDk5NkM0LjEyNSAyLjE1MDQ1IDQuMTY0NTMgMi4wNTUwMiA0LjIzNDkgMS45ODQ2NUM0LjMwNTI2IDEuOTE0MjkgNC40MDA3IDEuODc0NzYgNC41MDAyMSAxLjg3NDc2QzQuNTk5NzIgMS44NzQ3NiA0LjY5NTE2IDEuOTE0MjkgNC43NjU1MiAxLjk4NDY1TDguNTE1NTIgNS43MzQ2NUM4LjU1MDM5IDUuNzY5NDggOC41NzgwNSA1LjgxMDg0IDguNTk2OTIgNS44NTYzNkM4LjYxNTc5IDUuOTAxODkgOC42MjU1IDUuOTUwNjggOC42MjU1IDUuOTk5OTZDOC42MjU1IDYuMDQ5MjUgOC42MTU3OSA2LjA5ODA0IDguNTk2OTIgNi4xNDM1N0M4LjU3ODA1IDYuMTg5MDkgOC41NTAzOSA2LjIzMDQ1IDguNTE1NTIgNi4yNjUyOFoiIGZpbGw9IiMxQTIxMkIiLz4KPC9nPgo8L3N2Zz4K" />
        				</div>
        				<div className="checkoutlink3">Booking Confirmation</div>
      			</div>
      			<div className="checkouticonButtonParent">
        				<div className="checkouticonButton" >
          					<div className="checkouticon3">

							  <Link  to={`/Browseshops/Sdp/${storeId}`} style={{ color: '#09a7b3' }}>
							  <img className="checkouticon7" alt="" src="/arrowleft.png" />
                   </Link>
            						
          					</div>
        				</div>
        				<div className="checkoutbookingConfirmation">Booking Confirmation</div>
      			</div>

				
      			<div className="checkoutframeParent">
        				<div className="checkoutaboutParent">
          					<div className="checkoutabout">
            						<div className="checkoutyourBookingParent">
              							<div className="checkoutyourBooking">Your Booking</div>
              							<div className="checkoutframeGroup">
                								<div className="checkoutgroupParent">
                  									<div className="checkoutdateParent">
                    										<div className="checkoutdate">Date</div>
                    										<div className="checkoutfeb22221k">{localStorage.getItem('selectedStartDate')} - {localStorage.getItem('selectedEndDate')}</div>
                  									</div>
                  									<div className="checkoutbutton">
                    										<div className="checkouticon8">
                      											
																  <Link  to={`/Browseshops/Sdp/${storeId}`} style={{ color: '#09a7b3' }}>
																  <img className="checkouticon9" alt="" src="/pencil.svg" />
                                                                          </Link>
            					
                    										</div>
                  									</div>
                								</div>
                								<div className="checkoutframeDiv">
                  									<div className="checkoutshelfsParent">
                    										<div className="checkoutdate">Shelfs</div>
                                                                  <div className="countshlefincheckout">
                                                                <div className="checkoutnoofshlef">{itemCount}</div>
                    										<div className="checkoutfeb22221"> Shelf</div>
															</div>
                  									          </div>
                  									<div className="checkoutbutton">
                    										<div className="checkouticon8">
															<Link  to={`/Browseshops/Sdp/${storeId}`} style={{ color: '#09a7b3' }}>
																  <img className="checkouticon9" alt="" src="/pencil.svg" />
                                                                          </Link>
                    										</div>
                  									</div>
                								</div>
              							</div>
            						</div>
          					</div>
          					<div className="checkoutrectangleParent">
            						<div className="checkoutframeChild" />


            						<div className="checkoutaddress">Address</div>
									
                              <div className="checkoutmapIcon"  >  
                                    <Mapcheckout  addressMatch = {addressMatch}/>
									</div>



            						   <div className="checkoutlocation">
              							<div className="checkoutbroadwayWestLondonWrapper">
											    <img   className = "mappin " src ="/MapPin.png"  alt = ""   style={{marginLeft : "-30px", marginTop : "-5px"}} />
                								<div className="checkoutbroadwayWest1" style={{width : "800px"}}> {address}</div>
              							</div>
            						  </div>
            						
          					         </div>
        				            </div>
        				<div className="checkoutimageParent">
          					<div className="checkoutimage1">
            						
            						<img className="checkoutimageIcon"  src = {mainImageUrl1} alt = "" />
          					</div>
          					<div className="checkoutdiv1">
            						<div className="checkoutbordeauxGetawayParent">
              							<div className="checkoutbordeauxGetaway">{storeData.StoreName}</div>
              							<div className="checkoutdivider1" />
              							<div className="checkoutbillingDetailsParent">
                								<div className="checkoutbillingDetails">Billing Details</div>
                								<div className="checkoutpriceDetailsParent">
                  									<div className="checkoutpriceDetails">
                    							
                    										<div className="checkoutrow1">
                      											<div className="checkouttotalShelves"> Shelves Rent </div>
                      										<div className="checkoutdiv3">£{totalRent1}</div>
                    										</div>
                    					
                    										    <div className="checkoutrow1">
                      											<div className="checkouttotalShelves">Booking Fee</div>
                      											<div className="checkoutdiv3">£20/shelf</div>
                    										    </div>
															    <div className="checkoutrow1">
                      											<div className="checkouttotalShelves">VAT</div>
                      											<div className="checkoutdiv3">20%</div>
                    										    </div>
                  									            </div>
                  									            <div className="checkoutrow6"  style={ { marginTop : "-20px"}}>
                    									     	<div className="checkouttotal">Total</div>
                    										    <div className="checkoutdiv8">£{totalRent}</div>
                  									            </div>
                								</div>
                								<div className="checkoutbutton2" style={ { marginTop : "-20px"}}>
                  									<div className="checkoutlabel" onClick={handleBookingConfirmation}>									
													  <Link  className="confrmbook" to={`/confirmation/${storeId}?StoreName=${StoreName}&mainImageUrl1=${mainImageUrl1}&address=${address}&itemCount=${itemCount}`}>
      Confirm Booking
        </Link>
</div>
                								</div>
              							</div>
            						</div>
          					</div>
        				</div>
      			</div>
    		</div>
  )
  }
export default CheckoutPage
