import React  from 'react';
import "./login.scss"
import { Link } from 'react-router-dom';
const Login= () => {

  {/*}
  const handleLinkClickadmin = () => {
    localStorage.setItem('role', 'ADMIN');
};
const handleLinkClickecom = () => {
  localStorage.setItem('role', 'ECOMM');
};
{*/}
const handleLinkClickadmin = () => {
  sessionStorage.setItem('role', 'ADMIN');
};
const handleLinkClickecom = () => {
sessionStorage.setItem('role', 'ECOMM');
};
return (
<div className='loginpage'>
  <div className="loginoptionsfull">
               <div className="loginheadline">
               Are you a retail store or an eCommerce brand ?
               </div>

         <div className="loginoptions">

         <Link    style = {{textDecoration : "none"}} to ="/Authpage1"  onClick={handleLinkClickadmin}>
        <div className="loginoption2">
        <div className="loginoption1head">  I'm a Retail store owner</div>
        <div className="loginoption1data"> Looking to list my shelf</div>
        </div>
        </Link>
        <Link    style = {{textDecoration : "none"}} to ="/Authpage1"  onClick={handleLinkClickecom}>
         <div className="loginoption3">
         <div className="loginoption1head3">  I'm a D2C brand </div>
         <div className="loginoption1data3">Looking to rent a shelf</div>
         </div>
        
         </Link>
    </div>
   


  </div>
</div>
    )}
    export default Login