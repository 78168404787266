import * as React from "react";
import { useState , useEffect} from 'react';


import "./App.css"

import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";

import Sdp from './pages/Sdp/Sdp';
import Checkout from "./pages/Checkout/Checkout";
import Confirmation from "./pages/Confirmation/Confirmation";
import Aboutus from "./pages/Aboutus/Aboutus";
import Home from "./pages/Home/Home";
import Browseshops from "./pages/Browseshops/Browseshops";
import Listyourshelf from "./pages/Listyourshelf/Listyourshelf";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Form1 from "./components/Form1/Form1";
import Form3 from "./components/Bankdetails/Form3";
import Form2 from "./components/Selfdetail/Form2";
import Form12 from "./components/Form1/Form12";
import Form13 from "./components/Form1/Form13";
import Form14 from "./components/Form1/Form14";
import Synergy from "./components/Rentashelf/Synergy";
import AuthPage from "./components/AuthPage/AuthPage";
import Login from "./pages/Login/Login";
import AuthPage1 from "./components/AuthPage1/AuthPage1";
import AuthPage2 from "./components/AuthPage2/AuthPage2";
import Blogs from "./pages/Blogs/Blogs";





import Privacy from "./pages/Privacy/Privacy";

import EcommDash from "./components/Dashboard_ecomm/EcommDash";
import { color } from "@mui/system";
import CookieConsent from "./pages/CookiesPopup/CookieConsent";
import { useCookies } from 'react-cookie';
import Blog_page_1 from "./pages/Blogs/Blog_content/Blog_page";
import Blog_page_2 from "./pages/Blogs/Blog_content/Blog_page_2";
import Blog_page_3 from "./pages/Blogs/Blog_content/Blog_page_3";
import Blog_page_4 from "./pages/Blogs/Blog_content/Blog_page_4";

const Layout = () => {
  
  const [authenticated, setAuthenticated] = useState(false);
  const [token, setToken] = useState('');
  const handleLogout = () => {
    // Your logout logic
    localStorage.removeItem('token');
    setAuthenticated(false);
    setToken('');
  };
  return (
    <div className="app">
      <Navbar  handleLogout={handleLogout}/>
      <Outlet />
      <Footer />
    </div>
  );
};  


const App = () => { 
  const [cookies] = useCookies(["cookieConsent"]);
  const [storeId, setStoreId] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);
  const [role, setRole] = useState('');
  const [token, setToken] = useState('');
  const handleLogout = () => {
    localStorage.removeItem('token');
    setAuthenticated(false);
    setToken('');
    }; 
    console.log(localStorage.getItem('role'))

  useEffect(() => {
    // Fetch role from session storage when component mounts
    const  user_role = localStorage.getItem('role');
    setRole( user_role);
  }, []);
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout  handleLogout={handleLogout} />,
      children: [
        { path: "/", element: <Home  /> },
        { path: "Aboutus", element: <Aboutus /> },
        {
          path: "Browseshops/Sdp/:storeId",
          element: <Sdp      setStoreId={setStoreId} />,
          props: { storeId: storeId } ,// Pass the storeId as a prop
      
        },
       
        {path : "/Blogs"  , element: <Blogs /> },
        {path : "/Ecomm_dashborad"  , element: < EcommDash/> },
        
        {path : "/Privacy"  , element: <Privacy /> },

       {path :  "/Blogs/1" , element : <Blog_page_4 />} ,
       {path :  "/Blogs/2" , element : <Blog_page_3 />} ,
       {path :  "/Blogs/3" , element : <Blog_page_2/>} ,
       {path :  "/Blogs/4" , element : <Blog_page_1/>} ,


     
        { path: "/Login", element : <Login /> },

        
        {
          path  : "/checkout/:storeId" ,
          element: <Checkout />,
        
        },
     
        { path: "/confirmation/:storeId", element: <Confirmation /> },
        
     
        { path: "/Listyourshelf/Form2", element: <Form2 storeId={storeId} /> },
        { path: "/Listyourshelf/Form3", element: <Form3 storeId={storeId} /> },
    
        { path: "/Listyourshelf/Form1", element: <Form1 setStoreId={setStoreId} token={token} /> },
        { path: "/Listyourshelf/Form1/Form12", element: <Form12 storeId={storeId} /> },
        { path: "/Listyourshelf/Form1/Form12/Form13", element: <Form13 storeId={storeId} /> },
        { path: "/Listyourshelf/Form1/Form12/Form13/Form14", element: <Form14 storeId={storeId} /> },
      ],
    },
    { path: "/Listyourshelf", 
    element: authenticated && role === 'ADMIN'? <Listyourshelf handleLogout={() => {
      setAuthenticated(false);
      setToken(null);
      localStorage.removeItem('authToken');
    } } token={token} /> : <Navigate to="/AuthPage" /> 
  },
  
    { path: "Browseshops", element: <Browseshops /> },
    { path: "/Synergy", element: <Synergy /> },
   
   
    { path: "/AuthPage", element: <AuthPage setAuthenticated={setAuthenticated}  setToken = {setToken}     /> },
    { path: "/AuthPage1", element: <AuthPage1 setAuthenticated={setAuthenticated}  setToken = {setToken}     /> },
    { path: "/AuthPage2", element: <AuthPage2 setAuthenticated={setAuthenticated}  setToken = {setToken} storeId={storeId}  /> }, 
 
  ]);

  return (

    <div className="App">
      <RouterProvider router={router} />
   
      {!cookies.cookieConsent && <CookieConsent />}
    </div>
  );
};

export default App;
