import React, { useEffect, useState } from 'react';
import "./sdpoverview.scss"



const SdpOverview = ({ shelfDetails }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Assume some logic to fetch shelf details
    // Once the data is fetched, set loading to false
    // setLoading(false);

    // For demonstration purposes, let's simulate loading delay
    const delay = setTimeout(() => {
      setLoading(false);
    }, 2000);

    // Clear the timeout to avoid memory leaks if the component unmounts
    return () => clearTimeout(delay);
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  //  console.log(shelfDetails);
  const shelfDescription = shelfDetails?.length > 0 ? shelfDetails[0].shelfDescription : '';
  // console.log(shelfDescription);

    return (
    <div>
      <div className="sdpshopOverviewParent">
                <div className="sdpshopOverview">Shelf overview</div>
                <div className="sdpcomeAndStayContainer">
                      <p className="sdpspaciousAndBright">{shelfDescription}</p>
                   
                </div>
               
          </div>

    </div>
  )
}

export default SdpOverview
