import React from 'react'
import "./aboutstatistics.scss"
const aboutstatistics = () => {
   return (
                 <div className="statisticsstatsParent">
                <div className="statistics_headlines">
                  <div className="statistics_main">Our waitlist</div>
                  <div className="statistics_brand">
                        <div className="stats_data">10+</div>
                        <div className="stats_desc">Brands trust us</div>
                  </div>
                  <div className="statistics_register">
                  <div className="stats_data">40+</div>
                        <div className="stats_desc">Stores registered with us</div>
                  </div>
                  <div className="statistics_cutostomer">
                  <div className="stats_data">100%</div>
                        <div className="stats_desc">Happy customers</div>
                  </div>
             

                </div>

 
                  </div>



                   
       
    
  )
}

export default aboutstatistics
