import React, { useState } from 'react';
import axios from 'axios';
import Select from 'react-select';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./user_form.scss";

const categories = [
  { value: 'Fashion', label: 'Fashion' },
  { value: 'Electronics', label: 'Electronics' },
  { value: 'Kids', label: 'Kids' },
  { value: 'DIY & Hardware', label: 'DIY & Hardware' },
  { value: 'Furniture & Interiors', label: 'Furniture & Interiors' },
  { value: 'Gardening', label: 'Gardening' }
];

const UserForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [location, setLocation] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const graphqlEndpoint = process.env.REACT_APP_GRAPHQL_ENDPOINT;




  const handleCategoryChange = (selectedOptions) => {
    setSelectedCategories(selectedOptions);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const selectedCategoryValues = selectedCategories.map(category => category.value);

    const mutation = `
      mutation {
        setUserNeed(
          name: "${name}",
          location: "${location}",
          category: "${selectedCategoryValues.join(', ')}",
          email: "${email}"
           ) {
          usersNeed_id
           }
           }
           ` ;

    try {
      const response = await axios.post(graphqlEndpoint, {
        query: mutation
      });

      if (response.data.errors) {
        throw new Error('An error occurred');
      }

      toast.success('Your query is submitted. We will react soon.');
    } catch (error) {
      toast.error('Error submitting your query. Please try again.');
      console.error('Error:', error);
    }
  };
  const [role, setrole] = useState('');

  const handleRoleChange = (e) => {
    setrole(e.target.value);
  };
  
  return (
    <div className='user_form_container'>
     
      <div className="logo_img_popup">
        <img className="logo_img_popup_img" src="https://fixedimages.s3.amazonaws.com/Logo.jpg" alt="Logo" />
      </div>
      <div className="headline_user_details">
        Hey there! we're in private beta and have just launched the FMCG category in london stores. if this isn't what you were looking for, please register your interest, and we'd be happy to help you explore offline distribution options.
      </div>
      <form >
        <div className="user_input_section">
          <div className="user_name_collection">
            <div className="tag_line_user">Name</div>
            <input
              type="text"
              className="user_input"
              placeholder="ex: robert"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="email_collection">
            <div className="tag_line_user">Email</div>
            <input
              type="email"
              className="user_input"
              placeholder="ex: abc@gmail.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="location_collection">
             <div className="location">
            <div className="location_user">Location</div>
            <input
              type="text"
              className="user_input_location"
              placeholder="ex: London"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              required
            />
            </div>
            <div className="role_of_request">
              <div className="role_of_user">About you</div>
              <select
        id="location"
        className="user_input_role"
        value={role}
        onChange={handleRoleChange}
        required
      >
        <option value="">Select your role</option>
        <option value="Store 1">Rent a shelf</option>
        <option value="Store 2">List your store</option>
       
      </select>
            </div>
          
          </div>
          <div className="category_collection">
            <div className="tag_line_user">Category</div>
            <Select
              isMulti
              options={categories}
              value={selectedCategories}
              onChange={handleCategoryChange}
              className="category_select"
              placeholder="Select categories"
            />
          </div>
          <div onClick={handleSubmit} type="submit" className="submit_button">Submit</div>
        </div>
      </form>
    </div>
  );
};

export default UserForm;
