import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import SdpHead from '../../components/sdphead/SdpHead';
import SdpSelf from '../../components/sdpself/SdpSelf';
import SdpOverview from '../../components/sdpOverview/SdpOverview';
import SdpTiming from '../../components/sdpTiming/SdpTiming';
//import SdpSimilar from '../../components/sdpSimilar/SdpSimilar';
import Calender from '../../components/calender/Calender';
import SdpMap from '../../components/sdpMap/SdpMap';
import "./sdp.scss"
import Storedatasdp from '../../components/sdpTiming/Storedatasdp';
import Navbar from '../../components/Navbar/Navbar';

const Sdp = () => {
  const graphqlEndpoint = process.env.REACT_APP_GRAPHQL_ENDPOINT;

  const storeId= useParams().storeId;
//  console.log('props StoreId:', storeId );

  const location = useLocation();
  const { state } = location;

//  console.log('Location:', location);

  const [shelfData, setShelfData] = useState([]);
  const [shelfDetails, setshelfDetails] = useState([]);

  useEffect(() => {
    if (storeId) {
      // Fetch all store records using the storeId
      const graphqlQuery = `
        query {
          getAllStoreRecordsById(Id: "${storeId}") {
            StoreName,
            ShopCategory,
            StoreAddress,
            AverageOrderValue,
            stroreImageUrl,
            selectCity,
            storeId,
            storeArea ,
            PaymentReconciliation,
            CustomerDemography,
            SelectedState,
            StoreType,
            storeTiming,
            shelfDetails {
              shelfName,
              shelf_id,
              shelfDescription,
              createdDate,
              shelfArea,
              shelfImage_url ,
              shelfRent,
            }
          }
        }
      `;
      axios.post(graphqlEndpoint, { query: graphqlQuery })
        .then(response => {
          // Log the entire store data
       //   console.log('All Store Data:', response.data.data.getAllStoreRecordsById);
          // Set the shelf data if needed
          setShelfData(response.data.data.getAllStoreRecordsById);
         const  shelfDetails = response.data.data.getAllStoreRecordsById.shelfDetails || [];
    //     console.log("testsdf11",shelfDetails)
          setshelfDetails(shelfDetails);
        })
        
        .catch(error => {
          console.error('Error fetching store data:', error);
        });
    }
  }, [storeId, graphqlEndpoint]);
 //  console.log("testsdf",shelfDetails)

 // console.log("final" ,shelfData)

 // console.log("storeid" , shelfData.storeId, )
  return (
    <div>
   
      <SdpHead shelfData={shelfData} />
      <SdpSelf shelfData= {shelfData} shelfDetails ={ shelfDetails } storeId={shelfData.storeId}/>
      <SdpOverview shelfDetails ={ shelfDetails } />  
      <Storedatasdp shelfData={shelfData} shelfDetails ={ shelfDetails }/>
      <SdpTiming  shelfData={shelfData} />
     
     
      <SdpMap  shelfData={shelfData} />

    </div>
  );
};

export default Sdp;