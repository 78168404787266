import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { Link } from 'react-router-dom';

const Synergydatatile2 = ({ onSelectedProductChange }) => {
  const graphqlEndpoint = process.env.REACT_APP_GRAPHQL_ENDPOINT;
  const [preferredProducts, setPreferredProducts] = useState([]);
  const [selectedOptionsDropdown, setSelectedOptionsDropdown] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);

  useEffect(() => {
    // Retrieve selected options from sessionStorage
    const selectedOptions = JSON.parse(sessionStorage.getItem('selectedOptions')) || [];

    const fetchPreferredProducts = async () => {
      try {
        const resultsArray = [];

        for (const option of selectedOptions) {
          const response = await axios.post(graphqlEndpoint, {
            query: `
              query {
                getStoreByRetailCategory(retailCategory: "${option}") {
                  preferredProduct
                }
              }
            `,
          });

          resultsArray.push(response.data.data.getStoreByRetailCategory.preferredProduct);
        }

        const allPreferredProducts = resultsArray.flat();
        setPreferredProducts(allPreferredProducts);
      } catch (error) {
        console.error('Error fetching preferred products:', error);
      }
    };

    if (selectedOptions.length > 0) {
      fetchPreferredProducts();
    }
  }, [graphqlEndpoint]);

  const handleSelectChange = (selectedOptions) => {
    setSelectedOptionsDropdown(selectedOptions);
    // Track selected preferred products in another array
    setSelectedProducts(selectedOptions.map(option => option.value));
    // Pass selected products to the parent component or perform other actions
    onSelectedProductChange(selectedProducts);
    sessionStorage.setItem('selectedProducts' ,selectedProducts ) ;
  };
  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: '325px',
      height: '48px'  , // Set your desired width for the entire component
      cursor: 'pointer',
    }),
    cursor:"pointer",
    menu: (provided) => ({
      ...provided,
      width: '325px', // Set your desired width for the dropdown menu
      borderRadius: 8, // Remove border-radius to eliminate the box around the menu
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)', // Remove box-shadow
    }),
  };
   //console.log(sessionStorage.getItem('selectedProducts'))
  return (
    <div className='synergydatatile1'> 
   <div className="synergydataleft">

        
<div className="logo_summary">
<div className="synergy_icon_left_top">
<Link to="/">
<img src="/synergy_logo.svg" alt="Synergy Logo" />
</Link>
</div>
<div className="synergy_text">Create the most affordable omnichannel experience for your product.</div>
</div>
<div className="synergy_insight_section">
<div className="synergy_insight_data">
 
  <div className="side_img_synergy_insight">
    
  <div className="synergy_insight_percentage">37%</div>
            <div className="img_animation_section">
         <img className="animated-image_insight top-right"  src="/icon_insight_1.svg" alt=""  />
         <img src="/icon_insight_2" alt="" className="animated-image_insight top-right" />
         <img src="/icon_insight_1" alt="" className="animated-image_insight top-right" />
         </div>
        </div> 
  <div className="synergy_insight_data_text">jump in web traffic for digital brands post entering physical retail stores. (Credits : Shopify)</div>
  <div className="synergy_insight_controller">
    <div className="synergy_insight_controller_left"></div>
    <div className="synergy_insight_progressbar"><img src ="/progessbar_2.svg" alt ="" /></div>
    <div className="synergy_insight_controller_right"></div>
    </div>
  </div>

</div>



</div>
   


      <div className="synergydataright">
        <div className="arrayofline">
          <div className="line1synergyyes"> </div>
          <div className="line1synergyyes"> </div>
          <div className="line1synergy"></div>
          <div className="line1synergy"></div>
          <div className="line1synergy"></div>
        </div>
        <div className="synergyrightfind">FIND YOUR SYNERGY</div>
        <div className="synergytell">Tell us more about your product ?</div>

        <div className="synergydrop2" >
          <div className="synergy3headoption">Select product</div>
          <Select
          isMulti
          value={selectedOptionsDropdown}
        
          styles={customStyles}
       
          options={preferredProducts.map(product => ({ value: product, label: product }))}
          onChange={handleSelectChange}
         
        />
        </div>
      </div>
    </div>
  );
};

export default Synergydatatile2;
