import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import "./Listyourshelf.scss"
//import Form13 from '../../components/Form1/Form13';
import {Link}  from "react-router-dom" 
import Store_Dashboard from './Store_Dashboard';
import Getting_started_store from './Getting_started_store';
import Retail_Invite from './Retail_Invite';
import RetailGeneral from './Profile/RetailGeneral';
import RetailPassword from './Profile/RetailPassword';
import RetailNotification from './Profile/RetailNotification';
import SuperAnalytics from './SuperAnalytics';
import ListingDetails from './Listing_details/ListingDetails';
import { Dashboard } from '@mui/icons-material';

const ProfileEditsSection = ({ activeProfileSection }) => {
    switch (activeProfileSection) {
        case 'general':
            return <RetailGeneral />;
        case 'notification':
            return <RetailNotification />;
        case 'privacy':
            return <RetailPassword />;
        default:
            return <RetailGeneral />;
    }
};

    const Listyourshelf = ({ handleLogout, token }) => {
        const [activeSection, setActiveSection] = useState('Getting Started');
        const [activeProfileSection, setActiveProfileSection] = useState('general');
    
    const renderSection = () => {
        switch (activeSection) {
          case 'Dashboard':
            return (<Store_Dashboard/> ) ;
           case 'Getting Started' :
            return <Getting_started_store /> ;
           
            case 'Retail Invitation' :
                return <Retail_Invite /> ;
            case 'Booking & Listing' :
                return <ListingDetails/> ;
            case 'Super_Analytics' :
                return <SuperAnalytics/> ;
                case 'Profile_edits':
                    return <ProfileEditsSection activeProfileSection={activeProfileSection} />;
          default:
          return <Store_Dashboard/>;
        }
      };
   
    const graphqlEndpoint = process.env.REACT_APP_GRAPHQL_ENDPOINT;

                                           const [user, setUser] = useState(null);
                                           useEffect(() => {
                                           const fetchUserData = async () => {
                                           try {
                                           const response = await fetch(graphqlEndpoint, {
                                           method: 'GET',
                                           headers: {
                                           Authorization: `Bearer ${token}`, // Include your authentication token
                                          'Content-Type': 'application/json',
                                           },  });
                                           if (response.ok) {
                                           const userData = await response.json();
                                           setUser(userData);
                                           console.log("idk" ,userData)
                                           } else {
                                           console.error('Error fetching user data');
                                           } } catch (error) {
                                           console.error('Error fetching user data', error); 
                                         }};
                                         fetchUserData();
                                         }, []); // Run once on component mount
    

                                           
    
   
      //localStorage.removeItem('user_3')
      const [userEmail, setUserEmail] = useState('');

  useEffect(() => {
   
    // Retrieve user email from localStorage
    const storedEmail = localStorage.getItem('user_email');

    // Update state with the retrieved email
    if (storedEmail) {
      setUserEmail(storedEmail);
    }
  }, []);

  // support email
  const receiverEmail = 'info@thirdretail.com'; 
  const handleEmailButtonClick = () => {
    const emailAddress = 'info@thirdretail.com'; 
    const subject = 'Share feedback';
    const body = "Dear ThirdRetail Team, I am writing to provide some feedback on my recent experience with the ThirdRetail website. " ;

    const mailtoLink = `https://mail.google.com/mail/?view=cm&fs=1&to=${emailAddress}&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    // Open the browser-based email client (in this case, Gmail)
    window.open(mailtoLink, '_blank');
  }

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  

const getSectionTitle = () => {
    switch (activeSection) {
        case 'Dashboard':
            return (
                <div className='header_dashboard'>
                        <img
        src={isSidebarOpen ? "/close_menu.svg" : "/open_menu.svg"}
        alt="Toggle Menu"
        onClick={toggleSidebar}
        className="toggle-image"
      />
                     <div className="header_section_logo"><img  src="./Dashboard.svg" alt ="img" /></div>
                     <div className="header_section">Dashboard</div>
                </div>
            ) ;
        case 'Getting Started':
            return (
                <div className='header_dashboard'>
                     <img
        src={isSidebarOpen ? "/close_menu.svg" : "/open_menu.svg"}
        alt="Toggle Menu"
        onClick={toggleSidebar}
        className="toggle-image"
      />
                     <div className="header_section_logo"><img src="gettingstarticon.svg" alt ="img" /></div>
                     <div className="header_section">Start here</div>                   
                </div>
            ) ; 
            case 'Booking & Listing':
                return (
                    <div className='header_dashboard'>
                        <img
        src={isSidebarOpen ? "/close_menu.svg" : "/open_menu.svg"}
        alt="Toggle Menu"
        onClick={toggleSidebar}
        className="toggle-image"
      />
                         <div className="header_section_logo"><img src="edit_store.svg" alt ="img" /></div>
                         <div className="header_section">Current status</div>                   
                    </div>
                ) ; 
            case 'Super_Analytics':
                return (
                    <div className='header_dashboard'>
                        <img
        src={isSidebarOpen ? "/close_menu.svg" : "/open_menu.svg"}
        alt="Toggle Menu"
        onClick={toggleSidebar}
        className="toggle-image"
      />
                         <div className="header_section_logo"><img  src="analytics.svg" alt ="img" /></div>
                         <div className="header_section">Super Analytics</div>                   
                    </div>
                ) ; 
            case 'Retail Invitation':
                return (
                    <div className='header_dashboard'>
                        <img
        src={isSidebarOpen ? "/close_menu.svg" : "/open_menu.svg"}
        alt="Toggle Menu"
        onClick={toggleSidebar}
        className="toggle-image"
      />
                         <div className="header_section_logo"><img src="invite_people.svg" alt ="img" /></div>
                         <div className="header_section">Invitation via E-mail</div>                   
                    </div>
                ) ;
                case 'Profile_edits':
                    return (
                        <div className="profile_dashboard">
                            <div className='header_dashboard'>
                            <img
        src={isSidebarOpen ? "/close_menu.svg" : "/open_menu.svg"}
        alt="Toggle Menu"
        onClick={toggleSidebar}
        className="toggle-image"
      />
                                 <div className="header_section_logo"><img src="ecomm_setting.svg" alt ="img" /></div>
                                 <div className="header_section">Account Settings</div>
                                 </div>   
                                <div className="edit_section_retailer">
                                <div className="tab general" onClick={() => setActiveProfileSection('general')}>General</div>
                            <div className="tab notification" onClick={() => setActiveProfileSection('notification')}>Notification</div>
                            <div className="tab privacy" onClick={() => setActiveProfileSection('privacy')}>Privacy</div>
                            </div>  
                                      
                        </div>
                    ) ;
        default:
            return (
                <div className='header_dashboard'>
                <div className="header_section_logo"><img  src="./Dashboard.svg" alt ="img" /></div>
                <div className="header_section">Dashboard</div>
           </div>
            ) ;
    }
};

   

        return (
            <div className="listing_store_dashboard">
                     <div className={`listing_sidebar_dashboard ${isSidebarOpen ? '' : 'hidden'}`}>
               
                        <div className="store_dashboard_menu">
                            <div className="top_dashboard_sidebar">
                            <div className="header_logo_listing">
                          <Link to="/">
                            <img src="/listyourshelf_logo.svg" alt="listing dashboard logo" />
                           </Link>
                           </div>
                        <div className="store_name_logo"> 
                               <div className="store_name_index">  {localStorage.getItem('user_name1')}</div>
                               <div className="store_logo">  <img className ="logo_sidebar" alt="store_owner_logo" src="user-account.svg" /></div>
                        </div>
                            <div className="getting_started" onClick={() => setActiveSection('Getting Started')}>
                                <div className="store_dashboard_logo"><img className ="logo_sidebar" src="gettingstarticon.svg" alt ="" /></div>
                                <div className="getting_started_with_listing">Start here</div>
                            </div>
                            <div className="dashboard_listing" onClick={() => setActiveSection('Dashboard')}>
                                <div className="store_dashboard_logo"><img className ="logo_sidebar" src="./Dashboard.svg" alt ="" /></div>
                                <div className="dashboard_of_listing">Dashboard</div>
                            </div>
                            <div className="edit_store_listing" onClick={() => setActiveSection('Booking & Listing')}>
                                <div className="store_dashboard_logo"><img className ="logo_sidebar" src="edit_store.svg" alt ="" /></div>
                                <div className="edit_listing">Current status</div>
                            </div>
                            <div className="marketplace_listing">
                                <div className="store_dashboard_logo"><img className ="logo_sidebar" src="marketplace.svg"  alt ="" /></div>
                                <Link className='Link' to = "/Browseshops" ><div className="marketplace_listing">Marketplace</div></Link>
                            </div>
                            <div className="super_analystics_listing" onClick={() => setActiveSection('Super_Analytics')}>
                                <div className="store_dashboard_logo"><img className ="logo_sidebar" src="analytics.svg" alt ="" /></div>
                                <div className="super_analytic_stores"> Super Analytics</div>
                            </div>

                            <div className="logout_dashboard" onClick={handleLogout} >
                                    <div className="store_dashboard_logo">
                                           <img className ="logo_sidebar" alt="logout" src="logout.svg" />
                                    </div>
                                    <div className="logout_dashboard">Logout</div>
                            </div>
                      

                            </div>
                            <div className="bottom_dashboard_sidebar">
                            <div className="edit_store" onClick={() => setActiveSection('Profile_edits')}>
                                <div className="store_dashboard_logo"><img className ="logo_sidebar" src="ecomm_setting.svg" alt ="" /></div>
                                <div className="editing_listing">Edit Profile</div>
                            </div>
                            <div className="sharefeedback_listing" onClick={handleEmailButtonClick} >
                                <div className="store_dashboard_logo"><img className ="logo_sidebar" src="sharefeedbackicon.svg" alt ="" /></div>
                                <div className="share_feedback_on_listing">Share Feedback</div>
                            </div>
                         
                            <div className="invite_people_listing"  onClick={() => setActiveSection('Retail Invitation')}>
                                <div className="lstore_dashboard_logo"><img className ="logo_sidebar"src="invite_people.svg" alt="" /></div>
                                <div className="invites_store_owner">Invite people</div>
                            </div>

                            </div>

                        </div>
                    </div>
                    <div className="listing_mainboard">
                        <div className="top_bar_listing_mainboard">
                        {getSectionTitle()}
                        </div>
                        <div className="main_board_section">   
                        {renderSection()}</div>
                       
                        </div> 
 
            </div>
            ) ; 
            } ;   
       
export default Listyourshelf ;
