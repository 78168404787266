import React from 'react'
import "./sdpself.scss"
import { Link } from 'react-router-dom';
import { useState  ,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import ShelfComponent from './ShelfComponent'; 
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Calender2 from '../calender/Calender2';

const SdpSelf = ({ shelfDetails, storeId, shelfData }) => {
  // Ensure `shelfData` is passed correctly and destructure `ShopCategory`
  const { ShopCategory } = shelfData || {}; // Provide a default empty object to avoid undefined errors

  //console.log("new data", shelfData); // Logs the correctly fetched data
  //console.log("Shop Category", ShopCategory); // Optional: to check the ShopCategory


   //  console.log("storeid at sdp" , storeId )
	  localStorage.setItem('storeId',storeId)
  //  console.log(localStorage)

//	console.log("at sdpshelf", shelfDetails)
	shelfDetails.forEach((shelf, index) => {
	//	console.log(`Shelf #${index + 1} - ID: ${shelf.shelf_id}, Rent: £${shelf.shelfRent}`);
	  });
	
    const shelfAreaArray = shelfDetails.map(shelf => shelf.shelfArea);

	//  console.log("at sdpshelf", shelfDetails);
	  const [selectedShelves, setSelectedShelves] = useState([]);

	  const handleShelfSelect = (selectedShelf) => {
		const updatedSelection = [...selectedShelves];
		const existingIndex = updatedSelection.findIndex((shelf) => shelf.rackNumber === selectedShelf.rackNumber);
	
		if (existingIndex !== -1) {
		  updatedSelection.splice(existingIndex, 1);
		} else {
		  updatedSelection.push(selectedShelf);
		}
	
		setSelectedShelves(updatedSelection);
	  };
	
	//  console.log("Selected Shelves: ", selectedShelves);



 
  const [totalRent, setTotalRent] = useState(0);

  // Retrieve number of days from local storage
  const no_of_days = localStorage.getItem('daysCount');
  const daysCount1 = no_of_days !== null ? parseInt(no_of_days, 10) : 1;

  // Calculate total rent based on selected shelves
  // Assuming you have this array
  const totalRent1 = selectedShelves.reduce((acc, selectedShelf) => {
    const shelfRentAsNumber = parseFloat(selectedShelf.shelfRent);
    return acc + shelfRentAsNumber;
  }, 0);

  // Calculate booking charge per shelf
  const Bookingchargerpershelf = selectedShelves.length;

  // Calculate total rent including booking charge
  const totalRent2 = totalRent1 * daysCount1 + 20 * Bookingchargerpershelf;

  // Apply additional charge
  const totalRentWithAdditionalCharge = totalRent2 + 0.20 * totalRent2;


  const applyCoupon = () => {
    // Retrieve coupon code from user input or any other source
    const couponInput = document.querySelector('.coupon_input_section').value; // Replace with actual coupon code

    // Check if the entered coupon code is valid
    // You can replace this with your own logic to validate the coupon code
    if (couponInput === 'SAVE20') {
      if (totalRent > 0) {
        // Apply $20 discount logic
        setTotalRent(totalRent - 20);
        // Show success notification
        toast.success('Coupon applied successfully!', { position: toast.POSITION.TOP_CENTER });
      } else {
        // Total rent is zero, show error notification
        toast.error('Cannot apply coupon: Please select items before applying discount.', { position: toast.POSITION.TOP_CENTER });
      }
    } else if (couponInput === 'SAVE100') {
      if (totalRent > 0) {
        // Apply 100% discount logic
        setTotalRent(1);
        // Show success notification
        toast.success('Coupon applied successfully!', { position: toast.POSITION.TOP_CENTER });
      } else {
        // Total rent is zero, show error notification
        toast.error('Cannot apply coupon: Please select items before applying discount.', { position: toast.POSITION.TOP_CENTER });
      }
    } else {
      // Invalid coupon code handling
      toast.error('Invalid Coupon Code', { position: toast.POSITION.TOP_CENTER });
    }
    
  };

  // Update total rent state whenever it changes
  useEffect(() => {
    setTotalRent(totalRentWithAdditionalCharge);
  }, [totalRentWithAdditionalCharge]);




  // State to track the visibility of the coupon code section 
  const [isCouponSectionVisible, setIsCouponSectionVisible] = useState(false);
  // Function to toggle the visibility of the coupon code section
  const toggleCouponSection = () => {
    setIsCouponSectionVisible(!isCouponSectionVisible);
  }






   const navigate = useNavigate();
   const [isAuthenticated, setIsAuthenticated] = useState(false);

   useEffect(() => {
     const authToken =  sessionStorage.getItem("ecomUser_id");
   
     setIsAuthenticated(!!authToken);
  
   }, [setIsAuthenticated, navigate]);
 
   const selectedStartDate = localStorage.getItem('selectedStartDate');
   const selectedEndDate = localStorage.getItem('selectedEndDate');
   const [isCalendarOpen, setCalendarOpen] = useState(false);

   const handleButtonClick = () => {
     setCalendarOpen(!isCalendarOpen);
   };


    const handleClick = () => {
      toast.error('Please select the shelf and date', {
        position: toast.POSITION.TOP_CENTER
      });
    };

    // generate  boking id 

    const generateRandomAlphaNumeric = () => {
      const alphanumeric = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
      let result = '';
      for (let i = 0; i < 4; i++) {
        result += alphanumeric.charAt(Math.floor(Math.random() * alphanumeric.length));
      }
      return result;
    };

    const getbooking_id = () => {
      const booking_id = generateRandomAlphaNumeric();
      const bookingIdWithPrefix = 'TR2024' + booking_id;
      sessionStorage.setItem('booking_id', bookingIdWithPrefix);
      // Optionally, you can perform any other actions here when the div is clicked
    };



  
    
  
     return (    
                        <div className="sdpmaincompent"> 

                        
                                                 <div className="sdpbigparent">
                                                    <div className="sdppart1">

			                      	                  <div className="totalshelfavailableandcalender">
                                                <div className="totalAvailableShelves">Total available shelves</div>
                                        <div className="calender_icon_dates">                                              
			                                          <div className="sdpplaceholder">	
                                                         {selectedStartDate ? (
                                                          <div className="sdpplaceholder">{selectedStartDate}  to {selectedEndDate}</div>
                                                           ) : (
                                                           <div>Please select dates</div>
                                                             )}  
                                                 </div>
                                                 <div className="sdpicon">
                                                  <img className="sdpicon1" alt=""  onClick={handleButtonClick} src="/calendersdp.svg"  style={{marginTop :  "10px"}}/>
                                                  <div className="onclickopencalender">
                                                   {isCalendarOpen && <Calender2 />} 
                                                  </div>                                        
                                                 </div>
                                                 </div>
                                                 </div>



                                                   <div className="sdptags">
                                                   <div className="sdptagboxall">All</div>
	                                                 <div className="sdptagbox">{ ShopCategory }</div>
                                                   </div>


					                                      	
                                                  <div className="sdpselfframeParent">
                                                     <div className="sdpselfrackParent">
                                                         {shelfDetails.map((shelf, index) => (
                   <ShelfComponent
                   key={index}
              rackNumber={index + 1}
              shelfImage={shelf.shelfImage_url}
			  shelfName={shelf.shelfName}
              shelfDescription={`This is a rack number ${index + 1} in the store`}
              shelfRent={shelf.shelfRent}
              shelfArea = {shelf.shelfArea}
			  onSelect={handleShelfSelect}
        
                />
               ))}
                </div>
              </div>
	           </div>
				
	


	




						                 <div className="sdpbreakbb">
                             <div className="sdpbreakpriceDetailsWrapper">
                             <div className="sdpbreakpriceDetails">
 
	                          <div className="datepickedforrent">
		                       	<div className="startdate">
		                       	<div className="datepicked1">Start date</div>
		                       	<div className="datepicked2">{localStorage.getItem('selectedStartDate')}</div>
		                      	</div>
	                        	<div className="datepicked"><img  className = "rightcalenderarrow " src = "/arrowright.svg" alt = "" /></div>
                           	<div className="enddate">
			                      <div className="datepicked1">End date</div>
			                      <div className="datepicked2" >{localStorage.getItem('selectedEndDate')}</div>
		                        </div>
	                          </div>
    

		<div className="totalshlefpickeddata">
		<div className="totalShelvesPicked">Total shelves picked </div>
		<div className="shelves">{`${selectedShelves.length} shelf`}</div>
		</div>
      <div className="sdpbreakrow2">
        <div className="sdpbreakbookingBreakup">Booking Breakup</div>
        <div className="rentdetailspershelf">
	  {selectedShelves.map((selectedShelf, index) => (
        <div key={index} className="sdpbreakrow3">
          <div className="sdpbreaktotalShelves">{`Shelf #${selectedShelf.rackNumber} Rent`}</div>
          <div className="sdpbreakdiv1">{`£${selectedShelf.shelfRent}`}</div>
        </div>
      ))}
	  </div>
      

      <div className="sdpbreakrow3">
        <div className="sdpbreaktotalShelves">Booking Fee</div>
        <div className="sdpbreakdiv1">£20/shelf</div>
      </div>

	  <div className="sdpbreakrow3">
        <div className="sdpbreaktotalShelves">VAT</div>
        <div className="sdpbreakdiv1">20%</div>
      </div>

        <div className="sdpbreakrow3">
        <div className="sdpbreaktotalShelves">No. of Days </div>
        <div className="sdpbreakdiv1">{no_of_days || 1}</div>
        </div>
        <div className="sdpbreakrow8">
        <div className="sdpbreakbookingBreakup">Total</div>
        <div className="sdpbreakdiv6">{`£${totalRent}`}</div>
       </div>
	 

{/*} coupon code application  */}

<div className="coupon_code_section">

      <div
        className="have_a_coupon_code_title"
        onClick={toggleCouponSection}
      >
        Have a Promo code <span>?</span>
      </div>

  {isCouponSectionVisible && (
        <div className="apply_code_division">
          <div className="coupon_input_field">
            <input className="coupon_input_section"
             />
          </div>
          <div className="apply_coupon_button"onClick={applyCoupon}>Apply Now</div>
        </div>
      )}
   
 
</div>



    </div>
  </div>

 
    <div className="sdpbreakbutton">
      <div className="gtcheckout1" style={{zIndex : -5 ,cursor: 'pointer'} }  onClick={handleClick}> Rent Now</div>
     
    {totalRent !== 0  && no_of_days > 1 &&(
      <div className="sdpbreaklabel1">
      
     
      <div>
    
    
      {isAuthenticated ? (
           <div>
           <Link 
           style={{ textDecoration: 'none' }}
           to={{
           pathname: `/checkout/${encodeURIComponent(storeId)}`,
           search: `?selectedShelves=${encodeURIComponent(
           JSON.stringify(selectedShelves)
           )}&totalRent1=${encodeURIComponent(totalRent1)}&totalRent=${encodeURIComponent(
           totalRent
           )}`,
           }}
           onClick={() => {
        //console.log("Values to be passed:", {  totalRent1, selectedShelves,  totalRent,  });
           }}>
           <div className="gtcheckout" style={{ cursor: 'pointer' }}  onClick= {getbooking_id}>Rent Now</div>
           </Link>
           </div> 
           ) : (
            <Link to="/AuthPage2" style={{ textDecoration: 'none' }}>
                <div className="gtcheckout" style={{ cursor: 'pointer' , }} onClick= {getbooking_id}>Rent Now</div>
            </Link>
        )}
        </div>

	 

 </div>
   ) } 
    </div>
 




</div>
</div>
    		</div>
			</div>
             
          


            
            );
}



export default SdpSelf
