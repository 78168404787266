import React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';


  const ShelfComponent = ({ rackNumber, shelfName, shelfImage, shelfDescription, shelfRent, onSelect  ,shelfArea}) => {
    const [isChecked, setIsChecked] = useState(false);
  
    const handleCheckboxChange = () => {
      setIsChecked(!isChecked);
      onSelect({ rackNumber, shelfName, shelfRent, isChecked: !isChecked });
    };
  // console.log(shelfImage)
  
  return (
    <div className="sdpselfrack">

                    <div className="shelf_details_sdp" >
                               <img className="shelf_image_sdp" alt="" src={shelfImage} />
                                  


                        <div className="shelf_desc_sdp">
                    
                                <div className="shelf_no_desc">
                                  <div className="sdp_shelf_no" style={{ fontWeight: "700" }}>{`Rack #${rackNumber}`}</div>
                                  <div className="sdp_self_desc" style={{ color: "#001325a3" }}>{shelfDescription}</div>
                                </div>
                                <div className="shelf_desc_rent_sdp">
                                            <div className="shelf_rent_sdp" >{`£${shelfRent}`}</div>
                                               <div className="" style={{fontSize : "15px"}}>/shelf</div>
                                                    </div>  

                      </div>


                    <div className="sdp_check_area">
                              <div className="sdp_check_box" >
                                 <input type="checkbox" checked={isChecked}  onChange={handleCheckboxChange}  style={{ width: '20px', height: '20px', borderRadius: '5px' }}/>
                                 <div className='sdp_shelf_area' > Select Shelf</div>
                              </div>
                             
                                   <div className='shelf_area'>•{shelfArea}sq. feet</div>
                  </div>
       
      </div>

    
       


       
     
      
    </div>
  );
};

ShelfComponent.propTypes = {
  rackNumber: PropTypes.number.isRequired,
  shelfImage: PropTypes.string.isRequired,
  shelfDescription: PropTypes.string.isRequired,
  shelfRent: PropTypes.number.isRequired,
 isSelected: PropTypes.bool,
};

export default ShelfComponent;


