import React from 'react'
import { useState , useEffect} from 'react';
import { Link } from 'react-router-dom';
import "./sdphead.scss"

const SdpHead = ({ shelfData }) => {
	const { StoreName, SelectedState ,  shelfDetails,stroreImageUrl , shelfImage_url,selectCity,storeArea} = shelfData;

  const shelfImage = shelfDetails && shelfDetails.length > 0
  ? shelfDetails[0].shelfImage_url
  : null;
  const shelfimageurl = shelfImage ?  shelfImage.split(",") : [] ;
  const shelfimagemain = shelfimageurl.length > 0 ?  shelfimageurl[0] : null ;
  const imageUrls = stroreImageUrl ? stroreImageUrl.split(',') : [];

  // Use only the first image URL
  const mainImageUrl1 = imageUrls.length > 0 ? imageUrls[0] : null;
  const mainImageUrl2 = imageUrls.length > 0 ? imageUrls[1] : null;

   // console.log("Sdpheadimage shelf image ", shelfImage);

    	// Assuming SelectedState is a string like the one you provided earlier
      const selectedState = SelectedState;

      // Extracting address
      const addressMatch = selectedState?.match(/"([^"]+)\"Latitude/);
      const address = addressMatch ? addressMatch[1].trim() : null;

      // Extracting latitude and longitude
      const latitudeMatch = selectedState?.match(/Latitude : ([-+]?\d*\.\d+)/);
      const longitudeMatch = selectedState?.match(/Longitude : ([-+]?\d*\.\d+)/);

      // Check if matches were found
      const latitude = latitudeMatch ? parseFloat(latitudeMatch[1]) : null;
      const longitude = longitudeMatch ? parseFloat(longitudeMatch[1]) : null;

      // State to hold location details
      const [locationDetails, setLocationDetails] = useState({
        address: address || '', // Remove leading spaces
        latitude: latitude,
        longitude: longitude,
      });


        // Update locationDetails on mount
        useEffect(() => {
        // Check if both latitude and longitude are not null
        if (latitude !== null && longitude !== null) {
          setLocationDetails({
          address,
          latitude,
          longitude,
          });
        }
        }, [address, latitude, longitude]);


  return (
    <div className='sdphead'>


    <div className="sdptext">

      		    	<div className="sdpnameWrapper">
        
          				    	     <div className="sdpbordeauxGetaway">{StoreName}</div>
          					         <div className="sdplocation">
            						          <img className="sdpmappinIcon" alt="" src="https://i.postimg.cc/5N2p70sf/MapPin.png" />
              					          <div className="sdpbroadwayWest1">{address}</div>  
          				     	      </div>
      			                  </div>

                <div className="sdptitle">
          					<div className="sdpshelvesInA1">Shelves in a top notch downtown market </div>
                    <div className="store_area_insdp">•{storeArea} sq.feet</div>
                </div>
    </div>



            <div className="sdpimage">
                    <div className="sdpimg1">
                        <img className='sdpimg1' src = {mainImageUrl1}alt ="" />
                    </div>

                    <div className="sdpimgsec">
                            <div className="sdpimg2">
                            <img className='sdpimg2' src = { shelfimagemain } alt ="" />
                            </div>
                            <div className="sdpimg3">
                            <img  className='sdpimg3' src = {mainImageUrl2} alt ="" />
                            </div>
                    </div>
            </div>



                  <div className="sdpbreadcrumbToDetach">
<div className="sdplinkhome"><Link to = "/"  style={{ color: "inherit"}}>Home</Link></div>
<div className="sdpicon">
<img className="sdpchevronRightIcon" alt="" src="/Chevron_Right.svg" />
</div>
<div className="sdplinkcity"><Link to = "/Browseshops"  style={{ color: "inherit"}}>Listings in {selectCity}</Link></div>
<div className="sdpicon">
<img className="sdpchevronRightIcon" alt="" src="/Chevron_Right.svg" />
</div>
<div className="sdplink2">{StoreName}</div>
</div>

    </div>
  )
  }

export default SdpHead

