import React, { useEffect, useState } from 'react';
import './ecomm_dash.scss';
import Cancelled from './Cancelled';
import SuperAnalytics from './SuperAnalytics';
import HelpCenter from './HelpCenter';
import { Link } from 'react-router-dom';
import Upcoming from './Upcomming';
import DashboardEcomm from './My_bookings';
import PastBooking from './PastBooking';
import My_bookings from './My_bookings';
import Dashboard from './Dashboard';
import Profile from './profile/Profile';
import Invite from './Invite';
import Connecting_store from './Connecting_store';

const EcommDash = () => {

  const [activeSection, setActiveSection] = useState('dashboard');
       



  const renderSection = () => {
    switch (activeSection) {
      case 'dashboard':
        return <Dashboard/>;
      case 'upcoming':
        return <Upcoming/>;
      case 'past':
        return <PastBooking />;
      case 'cancelled':
        return <Cancelled />;
      case 'superAnalytics':
        return <SuperAnalytics />;
        case 'connectStore':
          return <Connecting_store />;
        case 'my_bookings':
          return <My_bookings />;
      case 'helpCenter':
        return <HelpCenter />;
        case 'profile':
        return <Profile />;
        case 'invite':
          return <Invite />;
      default:
      return <Dashboard/>;
    }
  };

 
  return (
    <div className='Dashboard_ecomm'>
      <div className="left_ecomm">
      <div className="left_ecomm_upper">

        <div className="left_ecomm_options" onClick={() => setActiveSection('dashboard')}>
          <img src="./Dashboard.svg" alt="" /> Dashboard
        </div>

        <div className="left_ecomm_options" onClick={() => setActiveSection('my_bookings')}>
          <img src="./my_booking.svg" alt="" /> My Bookings
        </div>
      
        <Link to="/Browseshops" style={{ textDecoration: 'none', color: 'black' }}>
          <div className="left_ecomm_options">
            <img src="./marketplace.svg" alt="" /> Marketplace
          </div>
        </Link>
        <div className="left_ecomm_options" onClick={() => setActiveSection('connectStore')}>
          <img src="./connect_store.svg" alt="" /> Connect Store
        </div>
        <div className="left_ecomm_options" onClick={() => setActiveSection('superAnalytics')}>
          <img src="./analytics.svg" alt="" /> Super Analytics
        </div>
        <div className="left_ecomm_options" onClick={() => setActiveSection('helpCenter')}>
          <img src="./user_help.svg" alt="" /> Help Center
        </div>
        </div>
        <div className="left_ecomm_bottom">
        <div className="left_ecomm_options" onClick={() => setActiveSection('profile')}>
          <img src="./ecomm_setting.svg" alt="" /> Edit Profile
        </div>
        <div className="left_ecomm_options" onClick={() => setActiveSection('invite')}>
          <img src="./invite_people.svg" alt="" /> Invite People
        </div>
     
      </div>
      </div>
      <div className="right_ecomm">
      
        <div className="data_section_dashboard">
          {renderSection()}
        </div>
      </div>
    </div>
  );
}

export default EcommDash;
