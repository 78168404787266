import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import SwiperCore, { Navigation } from 'swiper/core';
import './Slider.css'; // Assuming Slider.css contains your custom styles
import { data } from './sliderData';

SwiperCore.use([Navigation]);

const Slider = () => {
  const swiperRef = useRef(null);

  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  return (
    <section className="slider-container">
      <div className="slider-content">
        <Swiper
          ref={swiperRef}
          spaceBetween={40}
          slidesPerView={3}
          centeredSlides={true}
          centeredSlidesBounds={true}
          setWrapperSize={true}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 10,
              width: 250,
              centeredSlides: false,
              centeredSlidesBounds: false,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 20,
              centeredSlides: false,
              centeredSlidesBounds: false,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 40,
              centeredSlides: false,
              centeredSlidesBounds: false,
            },
          }}
          className="why-join-us-slider"
          navigation={false} // Disable Swiper's built-in navigation
        >
          {data.map((item) => (
            <SwiperSlide className="slider-card" id="slider-card" key={item.id}>
              <div className="slider-card-image">
                <img src={item.image} alt="" />
              </div>
              <div className="slider-card-title">{item.title}</div>
              <div className="slider-card-description">{item.description}</div>
            </SwiperSlide>
          ))}
        </Swiper>
		<div className="button_change">
		<img className="prev-button" src = "./left_arrow.png" alt = ""  onClick={goPrev}/>
	    <img  className="next-button" src = "./right_arrow.png" alt =""  onClick={goNext}/>
		</div>
      </div>
    </section>
  );
};

export default Slider;
