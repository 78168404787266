import React from 'react'
import "./sdpTiming.scss"
import { useState ,useEffect } from 'react';
const Storedatasdp = ({ shelfData  ,shelfDetails}) => {
	const { ShopCategory, AverageOrderValue, PaymentReconciliation,  StoreType, storeTiming  } = shelfData;
  
  const shelfAreaArray = shelfDetails.map(shelf => shelf.shelfArea);


 
      //console.log("area" ,shelfAreaArray); 
    const [CustomerDemography, setCustomerDemography] = useState([]);
    // Assuming that shelfData contains CustomerDemography
    useEffect(() => {
      if (shelfData && shelfData.CustomerDemography) {
        setCustomerDemography(shelfData.CustomerDemography);
      }
    }, [shelfData]);

  //console.log('Number of elements in CustomerDemography:', CustomerDemography.length);
  const displayDemography = CustomerDemography.length > 1 ? 'All Demography' : CustomerDemography.join(', ');
   // console.log(displayDemography)

	// console.log(storeTiming)
  return (
   
      
	  <div className="businessinfo">
            <div className="sdpbusinessdetails1">
              
            <div className="sdpbox1">
                <div className="sdpboximgandtext">
                <div className="sdpboximg"> <img src="/averageorder.png" alt="" /></div>
                <div className="sdpanddetails">
				<div className="sdptextdemo">{AverageOrderValue}</div>
                  <div className="sdpdetailscat">Average Order Value</div>
                </div>
                </div>
              
              </div>
              <div className="sdpbox1">
                <div className="sdpboximgandtext">
                <div className="sdpboximg"><img src="/custd.png" alt="" /></div>
                <div className="sdpanddetails">
				<div className="sdptextdemo">{ displayDemography}</div>
                  <div className="sdpdetailscat">Customer Demography</div>
                </div>
                </div>
              
              </div>  <div className="sdpbox3">
                <div className="sdpboximgandtext">
                <div className="sdpboximg"  style={{margin :'20px' , marginLeft : '50px '}}><img src="/bestsuited.png" alt="" /></div>
                <div className="sdpanddetails"  style={{textAlign : "left"}}>
                  <div className="sdptextdemo_extra" style={{margin :'22px' , marginLeft : '-10px ' ,marginTop : "30px"}} >{ShopCategory}</div>
                  <div className="sdpdetailscat_extra">Best Suited</div>
                </div>
                </div>
              
              </div>
              

            </div>
            <div className="sdpbusinessdetails2 ">
            <div className="sdpbox1">
                <div className="sdpboximgandtext">
                <div className="sdpboximg"><img src="/shelflife.png" alt="" /></div>
                <div className="sdpanddetails">
                  <div className="sdptextdemo">{shelfAreaArray} sq feet</div>
                  <div className="sdpdetailscat">Shelf Area</div>
                </div>
                </div>
              
              </div>  <div className="sdpbox1">
                <div className="sdpboximgandtext">
                <div className="sdpboximg"><img src="/storetype.png" alt="" /></div>
                <div className="sdpanddetails">
                  <div className="sdptextdemo">{StoreType}</div>
                  <div className="sdpdetailscat">Store Type</div>
                </div>
                </div>
              
              </div>  <div className="sdpbox3">
                <div className="sdpboximgandtext">
                <div className="sdpboximg"  style={{textAlign: 'center', margin: '20px'}}><img src="/payment.png" alt="" /></div>
                <div className="sdpanddetails">
                  <div className="sdptextdemo_extra" style={{textAlign: 'center', margin: '25px', marginLeft :"0px ", marginTop:"30px"}}>{PaymentReconciliation}</div>
                  <div className="sdpdetailscat_extra" >Payment reconciliation</div>
                </div>
                </div>
              
              </div>

            </div>


            </div>


) }
export default  Storedatasdp 