
import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Link } from 'react-router-dom';
import "./blogs.scss" ;

import blog_4 from './blog_images/blog_1.jpg' ;
import blog_3 from './blog_images/blog_2.jpg';
import blog_2 from './blog_images/blog_3.jpg';
import blog_1 from './blog_images/blog_4.jpg';
import blog_5 from './blog_images/blog_5.jpg';
import blog_6 from './blog_images/blog_6.jpg';

const Blogs = () => {
    const [enquiryEmailId, setEnquiryEmailId] = useState('');

    const handleInputChange = (event) => {
        setEnquiryEmailId(event.target.value);
    };

    const handleInvite = async () => {
        if (!enquiryEmailId) {
            toast.error('Email must be filled.');
            return;
        }
        const mutation = `
        mutation {
          setUserJourney(
            ecommUserId: null,
            userPath: null,
            userId: null,
            enquiryEmailId: "${enquiryEmailId}"
          ) {
            userPath
            ecommUser {
              FirstName
            }
          }
        }
      `;

      try {
          const response = await axios.post(process.env.REACT_APP_GRAPHQL_ENDPOINT, {
              query: mutation,
          });

          const result = response.data.data.setUserJourney;
          if (result) {
              toast.success('Subscribed successfully!');
          } else {
              toast.error('Error setting journey.');
          }
      } catch (error) {
          toast.error(`Request failed: ${error.message}`);
      }
  };
    const blogsData = [
      
        {
            id: 1,
            blog_Date: "June 30, 2024",
            blog_img: blog_1,
            blog_headline: "Harnessing Data for the Future of Retail",
            blog_desc: "Harnessing Data for the Future of Retail: Developing Effective Machine Learning Algorithms            "
          } ,  {
            id: 2,
            blog_Date: "June 1, 2024",
            blog_img: blog_2,
            blog_headline: "The Future of Retail",
            blog_desc: " Seamlessly Integrating Online and Offline Channels"
          } , {
            id: 3,
            blog_Date: "April 10 , 2024",
            blog_img: blog_3,
            blog_headline: "Maximising In-Store Profit Per Square Foot",
            blog_desc: "Strategies for Brick-and-Mortar Revenue Optimization"
          } , {
          id: 4,
          blog_Date: "March 25, 2024",
          blog_img: blog_4,
          blog_headline: "eCommerce product distribution",
          blog_desc: "Proven Strategies to Streamline Ecommerce Product Distribution ." ,
        },
    
       
          
         /*}   {
            id: 5,
            blog_Date: "March 1, 2016",
            blog_img: blog_5,
            blog_headline: "Fifth Blog Headline",
            blog_desc: "Description of the second blog."
          } ,
          {
            id: 6,
            blog_Date: "March 1, 2016",
            blog_img: blog_6,
            blog_headline: "Second Blog Headline",
            blog_desc: "Description of the second blog."
          } ,  {*/ 
    ];

  
    const [email, setEmail] = useState('');

    const subscribe = async () => {
        try {
            const response = await axios.post('/subscribe', { email });
            
            if (response.status === 200) {
                alert('Thank you for subscribing!');
            } else {
                alert('Failed to subscribe. Please try again later.');
            }
        } catch (error) {
            console.error('Error subscribing:', error);
            alert('Failed to subscribe. Please try again later.');
        }
    };
    return (
        <div className='blogs_section'>

            <div className="blogs_headtag_blur">
                <div className="blogs_head_text">Blog & Insights| Have a look at some interesting reads  </div>

                
                <div className="social_accounts_blog">  <a className="social-fb-third-retail" href="https://www.linkedin.com/company/thirdretail/" target="_blank" rel="noreferrer">
                <div className="blog_link_head">ThirdRetail on LinkedIn -{'>'}</div>
                              </a> </div>
            </div>
        
            <div className="blogs-container">
            {blogsData.map(blog => (
               <Link  className = "Link" to={`/Blogs/${blog.id}`}>
            <div className="blog-item" key={blog.id}>
             
                <div className="blog_img_sec">
                  <img className="blog_img" src={blog.blog_img} alt="Blog" /> 
                </div>
                <div className="blog-content">
                  <div className="blog-date"> Blog : # {blog.id} | <span style={{ color: '#707070' }}>{blog.blog_Date}</span></div>
                  <div className="blog-headline">{blog.blog_headline}</div>
                  <div className="blog-desc">{blog.blog_desc}</div>
                  <div className="blog_full_read">Read More -{'>'}</div>
                </div>
            
            </div>
            </Link>
          ))}
                <div className="blog_underline"></div>
            </div>

            <div className="subscribe_section_newsletter">
            <div className="subscribe_head">Learn more about ThirdRetail</div>
            <div className="subscribe_sub_heading">Are you an investor? Receive our deck and necessary details of our pre-seed round!</div>
            <div className="subscribe_email_button">
                <div className="input_subscribe_field">
                <input
                        className='placeholder_general_ecomm_invite'
                        type="email" 
                        id="email" 
                        name="email"
                        value={enquiryEmailId}
                        onChange={handleInputChange}
                        placeholder="Enter your enquiry email"
                    />
                   
                </div>
                <div className="subscribe_button">
                    <div className="subscribe_border">
                        <div className="subscribe_text"  onClick={handleInvite}>Subscribe</div>
                        <ToastContainer />
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
}

export default Blogs;
