import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';

const Privacy = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(true);

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  useEffect(() => {
    const fetchUserEmail = () => {
      const curr_user_email = sessionStorage.getItem('EcomUserEmail');

      if (!curr_user_email) {
        toast.error('User email not found in session storage.');
        setLoading(false);
        return;
      }
      setUserEmail(curr_user_email);
      setLoading(false);
    };

    fetchUserEmail();
  }, []);

  const handleUpdatePassword = async () => {
    if (newPassword !== confirmPassword) {
      toast.error('Passwords do not match.');
      return;
    }

    if (  !newPassword || !confirmPassword) {
      toast.error('New password and Confirm password is required');
      return;
    }
    const query = `
      query {
        updateEcommUser(userPassword: "${newPassword}", userEmail: "${userEmail}")
      }
    `;

    try {
      const response = await axios.post(process.env.REACT_APP_GRAPHQL_ENDPOINT,{ query });

      if (response.data.errors) {
        toast.error(response.data.errors[0].message);
      } else {
        toast.success('Password updated successfully.');
        closeDialog();
      }
    } catch (error) {
      toast.error('An error occurred while updating the password.');
    }
  };

  return (
    <div className='privacy_container_ecomm'>
      <div className="privacy_section_ecomm">
        <div className="lock_password_icon_ecomm">
          <img src="./password_lock.svg" alt="" />
        </div>
        <div className="password_change_text_ecomm">
          <div className="password_change_text_ecomm_headline">Password</div>
          <div className="password_change_text_ecomm_subhedline">
            Change the account Password for your account.
          </div>
        </div>
      </div>
      <div className="change_password_ecomm_button" onClick={openDialog}>
        Change Password
      </div>
      {isDialogOpen && (
        <div className="overlay_ecomm">
          <div className="dialog_ecomm">
            <div className="headline_dialog_ecomm">Change Password</div>
            <div className="existing_password_ecomm_head_placeholder">
              <div className="existing_password_ecomm">User Email</div>
              <div className="placeholder_privacy_ecomm">
                <input
                  className='placeholder_general_ecomm_privacy'
                  value={userEmail}
                  readOnly
                />
              </div>
            </div>
            <div className="new_password_ecomm_head_placeholder">
              <div className="existing_password_ecomm">New password</div>
              <div className="placeholder_privacy_ecomm">
                <input
                  className='placeholder_general_ecomm_privacy'
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>
            </div>
            <div className="confirm_password_ecomm_head_placeholder">
              <div className="existing_password_ecomm">Confirm new password</div>
              <div className="placeholder_privacy_ecomm">
                <input
                  className='placeholder_general_ecomm_privacy'
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
            </div>
            <div className="bottom_section_buttons_ecomm">
              <div className="cancel_ecomm_privacy" onClick={closeDialog}>Cancel</div>
              <div className="update_button_password_ecomm" onClick={handleUpdatePassword}>Update</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Privacy;
