import React, { useEffect , useState } from 'react';
import "./Form1.scss" ;
import axios from 'axios';
import "./Form1.scss"
import Select from 'react-select';
import {Link}  from "react-router-dom" 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Multiselect from 'multiselect-react-dropdown';


const Form12 = (props) => {
                   const graphqlEndpoint = process.env.REACT_APP_GRAPHQL_ENDPOINT;
                   const [isSaveButtonClicked, setIsSaveButtonClicked] = useState(false);
	                 const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
                   const handleSaveClick = async () => {
		               try {
		               setIsSaveButtonClicked(true);
		               toast.success('Data saved successfully!');
		               setIsNextButtonDisabled(false); 
		               } catch (error) {
		               console.error('Save error:', error);   } };
                   const handleNextClick = () => {
                   if (!isSaveButtonClicked) {
                   toast.error('Please click "Save" before proceeding to "Next".');
                   } else {
                   toast.info('Proceeding to the next step.');
                   } };





        const [formData7, setFormData7] = useState({
        id: props.storeId  || "",
        storeType: [],
        averageOrderValue: '', // Assuming averageOrderValue is a Long on the backend
        customerDemographics: [], // Assuming customerDemographics is a List<String> on the backend
        paymentReconciliation: '',
        prefferedProduct: [], // Assuming prefferedProduct is a List<String> on the backend
        shopCategory: [], // Assuming shopCategory is a List<String> on the backend
          });

       useEffect(() => {
        setFormData7((prevFormData7) => ({ ...prevFormData7, id: props.storeId || '' }));
      }, [props.storeId]);
     

       // 1--cutomer demographicss 2-- averageOrderValue 3-- payemet-recoincailation  4-- prefereeed product 


       const [selectedType, setSelectedType] = useState(''); 
       const [selectedAverageOrderValue, setSelectedValue] = useState('');
       const [selectedDemographics, setSelectedDemographics] = useState('');
       const [selectedReconciliation, setSelectedReconciliation] = useState('');
       const [selectedCategory, setSelectedCategory] = useState('');
       const [preferredProducts, setPreferredProducts] = useState([]);
       const [selectedProducts, setSelectedProducts] = useState([]);
     
     
     
       // List of states and cities (replace with your actual data)
       const Types = ['Chain Store', 'Small Store', 'Brand Store' , 'Supermarket','Multi Brand','Boutique Store','Others'];
       const Values = ['under £50', '£50 -£100', '£100-£200','£200-£500','over £500',];
       const Demographicss = ['Baby products', 'Minor', '18-24' , '25-35','45-65','65+'];
       const Reconciliations = ['30 days', '60 days ', '90+ days'];
     //  const Products = ['Fresh Products', 'Packaged goods', 'Alcohol', "Men's clothing" , "Women's clothing" ,"Televisons",'Computers'] ;
       const Categorys = ['FMCG', 'Fashion','Kids','Furniture & Interior' , 'Health and beauty' , 'Sports' , 'Office supplies' , 'Pet supplies','Electronics','Books and music','DIY & Harware','Gardening'];
     
       useEffect(() => {
        // Fetch preferred products when the component mounts or when the selected category changes
        if (selectedCategory) {
          fetchPreferredProducts(selectedCategory);
        }
      }, [selectedCategory]);

       const handlestoreTypeChange = (event) => {
        setSelectedType(event.target.value);      
      };
      const handleCustomerDemographicsChange = (selectedOptions) => {
        setSelectedDemographics(selectedOptions);
        setFormData7({ ...formData7, customerDemographics: selectedOptions.map(option => option.value) });
      };

          const handleaverageOrderValueChange = (event) => {
            setSelectedValue(event.target.value); 
          };

       
          const handlepaymentReconciliationChange = (event) => {
            setSelectedReconciliation(event.target.value); 
          };
          // Function to handle city selection
          const handleSubmit = async (e) => {
            e.preventDefault();
            
            // Include the selected products in your mutation or API call
       //     console.log('Selected Products:', selectedProducts);
          };
          const handleCategoryChange = (selectedOption) => {
            setSelectedCategory(selectedOption.value);
          };
        
          const handleProductChange = (selectedOptions) => {
            setSelectedProducts(selectedOptions);
          };
       
          const fetchPreferredProducts = async (category) => {
            try {
              const response = await axios.post(graphqlEndpoint, {
                query: `
                  query {
                    getStoreByRetailCategory(retailCategory: "${category}") {
                      preferredProduct
                    }
                  }
                `,
              });
        
              // Update the preferredProducts state with the fetched data
              setPreferredProducts(response.data.data.getStoreByRetailCategory.preferredProduct);
        
         //     console.log(response.data.data.getStoreByRetailCategory.preferredProduct);
            } catch (error) {
              console.error('Error fetching preferred products:', error);
            }
          };
     
        
   
     
       const handleSubmit7 = async (e) => {
         e.preventDefault();
     
         try {
           // Make a POST request to the GraphQL endpoint
           const response = await axios.post(graphqlEndpoint, {
             query: `
               mutation storeBusinessInformation(
                 $id: Long!,
                 $storeType: [String]!,
                 $averageOrderValue:  String!,
                 $customerDemographics: [String]!,
                 $paymentReconciliation: String!,
                 $prefferedProduct: [String]!,
                 $shopCategory: [String]!
               ) {
                 storeBusinessInformation(
                   id: $id,
                   storeType: $storeType,
                   averageOrderValue: $averageOrderValue,
                   customerDemographics: $customerDemographics,
                   paymentReconciliation: $paymentReconciliation,
                   prefferedProduct: $prefferedProduct,
                   shopCategory: $shopCategory
                 )
               }
             `,
             variables: {
               id: parseInt(formData7.id), // Assuming id is a Long on the backend
               storeType: formData7.storeType,
               averageOrderValue: formData7.averageOrderValue, // Convert to Long
               customerDemographics: formData7.customerDemographics,
               paymentReconciliation: formData7.paymentReconciliation,
               prefferedProduct: formData7.prefferedProduct,
               shopCategory: formData7.shopCategory,
             },
           });
     
           // Handle the response data
     //      console.log(response.data);
           const storeBusiness = response.data.data.storeBusinessInformation;
           setFormData7({
            id: props.storeId  || "",
             storeType: [],
             averageOrderValue: '',
             customerDemographics: [],
             paymentReconciliation: '',
             prefferedProduct: [],
             shopCategory: [],
           });
         } catch (error) {
           // Handle errors
           console.error(error);
         }
       };
       const customStyles = {
        control: (provided) => ({
          ...provided,
          width: '350px',
          height: '48px'  , 
          borderRadius: 8 , // Set your desired width for the entire component
        }),
        menu: (provided) => ({
          ...provided,
          width: '325px', // Set your desired width for the dropdown menu
          borderRadius: 12 , // Remove border-radius to eliminate the box around the menu
          boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)', // Remove box-shadow
        }),
      };
      
 
       return (
        
  
<div className="form1secondblock">
  
    		<div className="businessInformation">
      			<div className="businessstore">
        				<div className="businessbusinessInformation1">Business Information</div>
        				<div className="businesstellUsAbout1">Tell us about your Store. These specifics will help us figure out the best clients for you.</div>
      			</div>
				  <form onSubmit={handleSubmit7}>
      			<div className="businessframeParent">
        				<div className="businessselectFieldParent">
          					<div className="businessselectField">
            						<div className="businesslabelWrapper">
                        <div className="businesslabel" style={{color:"#728197"}}>Customer demography</div> 
            						</div>
            					


<Select
  isMulti
  options={Demographicss.map(type => ({ value: type, label: type }))}
  
  styles={customStyles}
  value={selectedDemographics}
  onChange={(selectedOptions) => handleCustomerDemographicsChange(selectedOptions)}

/>

            					
          					</div>
          					<div className="businessselectField">
            						<div className="businesslabelWrapper">
              							<div className="businesslabel" style={{color:"#728197"}}>Payment reconciliation</div>
            						</div>
            						<div className="businessinput">  
                                    
                                    <select
                    value={selectedReconciliation|| formData7.paymentReconciliation} // Use a default value if needed
					onChange={(e) => {
					  setSelectedReconciliation() ;
					  setFormData7({ ...formData7, paymentReconciliation: e.target.value });
					}}
					className="input1"
                    id ="businessinput"
                >
                  <option value=""  >Select an option...</option>
                  {Reconciliations.map((Reconciliation, index) => (
                    <option key={index} value={Reconciliation}>
                      {Reconciliation}
                    </option>
                  ))}
                          </select>
            						</div>
          					</div>
        				</div>
        				<div className="businessselectFieldParent">
          					<div className="businessselectField">
            						<div className="businesslabelWrapper">
              							<div className="businesslabel" style={{color:"#728197"}}>Store type</div>
            						</div>
            						<div className="businessinput">
                                    <select
                                
                    value={selectedType || formData7.storeType} // Use a default value if needed
					onChange={(e) => {
           
            
					  setSelectedType() ;
					  setFormData7({ ...formData7, storeType: e.target.value }); 
					}}
					className="input1"
                    id ="businessinput"
                >
                  <option value=""  >Select an option...</option>
                  {Types.map((Type, index) => (
                    <option key={index} value={Type}>
                      {Type}
                    </option>
                            
                  ))} 
                   
             
           
</select>




            						</div>
          					</div>
          					<div className="businessselectField">
            						<div className="businesslabelWrapper">
                        <div className="businesslabel" style={{color:"#728197"}}>Shop Category</div> 
            						</div>
            						<div className="businessinput">
                        



                        <Select  
  value={selectedCategory || formData7.shopCategory}
  styles={customStyles}
  options={Categorys.map(category => ({ value: category, label: category }))}
  onChange={(selectedOption) => {
    setSelectedCategory(selectedOption);
    setFormData7({ ...formData7, shopCategory: selectedOption.value });
    handleCategoryChange(selectedOption);
  }}
/>

            						</div>
          					</div>
        				</div>

        				<div className="businessselectFieldParent">
          					<div className="businessselectField">
            						<div className="businesslabelWrapper">
              							<div className="businesslabel" style={{color:"#728197"}}>Average order value</div>
            						</div>
            						<div className="businessinput">

<select
                                
                                value={selectedAverageOrderValue || formData7.averageOrderValue} // Use a default value if needed
                      onChange={(e) => {
                       
                        
                        setSelectedValue() ;
                        setFormData7({ ...formData7, averageOrderValue: e.target.value }); 
                      }}
                      className="input1"
                                id ="businessinput"
                            >
                              <option value=""  >Select an option...</option>
                              {Values.map((Value, index) => (
                                <option key={index} value={Value}>
                                  {Value}
                                </option>
                            ))}     
            </select>
            

            						</div>
          					</div>
          					<div className="businessselectField">
            						<div className="businesslabelWrapper">
              							<div className="businesslabel" style={{color:"#728197"}}>Preferred product </div>
            						</div>
            						<div className="businessinput">
          
                        <Select
  isMulti
  value={selectedProducts}
  styles={customStyles}

  options={preferredProducts.map(product => ({ value: product, label: product }))}
  onChange={(selectedOptions) => {
    setSelectedProducts(selectedOptions);
    setFormData7({ ...formData7, prefferedProduct: selectedOptions.map(option => option.value) });
    handleProductChange(selectedOptions);
  }}
/>






            						</div>
          					</div>
        				</div>
      			</div>
				 















      			<div className="businessform">

   
            <div className="linksummit">
        <button type="submit" id="buttonk" onClick={handleSaveClick}>
          Save
        </button>
        <Link className={`linkform ${isNextButtonDisabled ? 'disabled' : ''}`} to={isSaveButtonClicked ? "Form13" : ""} onClick={handleNextClick} style = {{textDecoration : "none"}}>
          Next
        </Link>
      </div>

            
      			</div>


       
      </form>
  


    		</div>



</div>

      


  
  )
}

export default Form12 ;





















