import { Link } from 'react-router-dom';
import Browsestore from '../Browsestore/Browsestore';

export const Hero = ({ handleClick }) => {
    
      return (
        <section className="rent-text-container">

                        <div className="beta-message">
                              <div className="rent-beta-message">
                                    <span className="rent-new-notifier">
                                          NEW
                                    </span>
                                    Marketplace private beta launched
                              </div>
                        </div>
                        <div className="rent-text-heading">
                              
                              <div className="text-heading-first">
                                    Rent a 
                                    <div className="text-highlight-shelf">
                                          <div className="rent-shelf-text">
                                                shelf
                                          </div>
                                          <div className="rent-shelf-smile-image">
                                                <img src="./hero-section/shelf-smile.png" alt="shelf"/>
                                          </div>

                                    </div>
                                    for
                              </div>

                              <div className="text-subheading">
                                    your products
                              </div>
                        </div>

                        <div className="rent-text-subheading">
                        Get your products in front of customers in the aisles of their favorite stores. It's like a pop-up shop ,but
way more convenient.
                        </div>
                        <div className="rent-btn-search">
                              <div className="rent-synergy-btn">
                                    <Link to="Synergy" className="rent-button-links">
                                          Get started
                                    </Link>
                                    
                              <Link to= "/Browseshops" className="early_acess_btn" onClick={ handleClick } >
 Request  early access
</Link>


                              </div>
                             
                              
                        </div>
        </section>
    )
}

