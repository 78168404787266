import React from 'react'
import "./aboutoptimise.scss"
import { Link } from 'react-router-dom';
const aboutoptimise = () => {
          return (
            <div className="optimisesection">
                  <div className="optimise_background">
                        <img  className="optimise_background_img" src= "/optimise_bg.png" alt ="" /> 
                        </div>
                   <div className="optimise_text_head_img">
                              <div className="optimise_text_img">
                                    <div className="optimise_text">
                                          <div className="optimise_headline">LIST WITH US</div>
                                          <div className="optimise_desc">Optimise your in-store revenue </div>
                                          <div className="optimise_button"> <img  src ="/optimise_button.png" alt ="" /> </div>
                                    </div>


                                             
                              <img className='optimise_phone' src= "/optimise_phone.svg"  alt = '' />
                              
                              <img className='optimise_img_1' src ="/optimise_img_1.svg" alt=' ' />
                              <img className='optimise_img_2' src ="/optimise_img_2.svg" alt=' ' />
                           
                                    
                      
                        </div>
                  </div>
            </div>);
}
    

export default aboutoptimise
