import React, {useState} from 'react'
import  "./Footer.css"
import { Link } from 'react-router-dom';


const Footer = () => {
      const [showMap, setShowMap] = useState(false);

      const handleButtonClick = () => {
        setShowMap(!showMap);
      };
      const scrollToTop = () => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            });
          };
      const handleEmailButtonClick = () => {
            const emailAddress = 'info@thirdretail.com'; 
            const subject = 'Enquiry';
            const body = 'Hello, I have an enquiry:';
        
            const mailtoLink = `https://mail.google.com/mail/?view=cm&fs=1&to=${emailAddress}&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        
            // Open the browser-based email client (in this case, Gmail)
            window.open(mailtoLink, '_blank');
          };

      return (
            <footer>
                  <div className="separator"/>

                  <div className="info-container">
                        <div className="about-us-container">
                        <div className="support">
                              <div className= "support-title">Support</div>
                         
                              <Link   onClick={scrollToTop} className="info-sub-text" to="/Privacy">Privacy</Link>
                              <Link   onClick={scrollToTop} className="info-sub-text" to="/Blogs">Blogs</Link>
                             
                              <div className= "info-sub-text_d">Sitemap</div>
                           {/*}   <div className= "info-sub-text_d">Cancellation Policy</div>{*/}
                        </div>
                        <div className="stores">
                              <div className= "store-title">Stores in cities</div>
                              <a href="/Browseshops" className="info-sub-text">Stores in london</a>
                              <a href="/Browseshops" className="info-sub-text">Stores in bath</a>
                              <a href="/Browseshops" className="info-sub-text">Stores in edinburgh</a>
                              <a href="/Browseshops" className="info-sub-text">Stores in manchester</a>
                        </div>
                        <div className="company-contact-details">
                              <div className= "company-contact-details-title">Contact details</div>
                              <div className= "info-sub-text_d map-integrated" style={{width : "300px" , cursor : "pointer"}} 
                                    onClick={handleButtonClick}>
                                          128 city road, london, united kingdom, EC1V 2NX   
                                    <span className="link-details"> 
                                          Click to see location in map
                                    </span>
                              </div>
                              <div className= "info-sub-text ">Company number: 15436229 </div>
                              <div className= "info-sub-text ">info@thirdretail.com </div>


                              <div className="mapcolumn" >
                                    {showMap && (
                                          <div className="modal-overlay">
                                                <div className="modal-content">
                                                      <button className="close-button" onClick={handleButtonClick}>
                                                      Close map
                                                      </button>
                                                      <iframe
                                                      title="Google Map"
                                                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d620.5577080481237!2d-0.0894101!3d51.5273259!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761d64c8d91b11%3A0x8fd10f948b8c6ef5!2s128%20City%20Rd%2C%20London%20EC1V%202NX%2C%20UK!5e0!3m2!1sen!2sin!4v1706387020448!5m2!1sen!2sin"
                                                      width="900"
                                                      height="600"
                                                      
                                                      style={{
                                                            border: 0,
                                                            borderRadius: '10px', // Adjust the value as needed
                                                      }}
                                                      allowFullScreen=""
                                                      loading="lazy"
                                                      referrerPolicy="no-referrer-when-downgrade"
                                                      >

                                                      </iframe>
                                                </div>
                                          </div>
                                    )}
                              </div>
                              </div>
                        </div>

                  </div>

                  <div className="separator-80"/>

                  <div className="copyright-container">
                        <div className="company-info">
                              <div className="third-retail-copyright">© 2024 ThirdRetail Limited</div>
                        </div>

                        <div className="currency-info">
                              <div className="language">
                                    <img className="globeIcon1" alt="" src="https://i.postimg.cc/B6Vh0fYj/globe.png" />
                                    <div className="englishUs">English (us)</div>
                              </div>
                              <div className="language">
                                    <img className="currencygbpIcon" alt="" src="https://i.postimg.cc/MHf9Z3Nd/Currency-Gbp.png" />
                                    <div className="englishUs"> Pounds</div>
                              </div>
                        </div>

                        <div className="company-socials">
                             
                      
                        <a className="social-fb-third-retail" href="https://www.instagram.com/thirdretail/?utm_source=ig_web_button_share_sheet&igsh=ODdmZWVhMTFiMw%3D%3D" target="_blank" rel="noreferrer">
                        <img className="social-icon" src="https://fixedimages.s3.amazonaws.com/Instagram.png" alt=""/>
                              </a>
  
                              <a className="social-fb-third-retail" href="https://www.linkedin.com/company/thirdretail/" target="_blank" rel="noreferrer">
                                    <img className="social-icon" alt="" src="https://fixedimages.s3.amazonaws.com/linkedin.png" />
                              </a>

                              <img className="social-icon"  onClick={handleEmailButtonClick}  alt="" src="https://fixedimages.s3.amazonaws.com/mail.png" />
                        </div>
                  </div>

            </footer>
      )         
}
    
  

export default Footer
