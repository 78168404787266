import React from 'react'
import { Link } from 'react-router-dom';

const Getting_started_store = () => {
    
  const registerStoreValue = localStorage.getItem('register_store');

    console.log(registerStoreValue)
  // Check if register_store has a non-null value
  const isRegisterStorePresent = registerStoreValue !== "";
  return (
    <div className='getting_start_registration'>

        <div className="form_containers">
                <div className="form_container_headline">
                    <div className="form_headline_main">Let’s get you started, Username</div>
                    <div className="form_container_subheadline">Take a few minutes and get the best out of ThirdRetail</div>
                </div>
            <div className="forms_sections">
                <div className="form_section_topbar">
                   <div className="form_section_headline">Retail Setup</div>
                   <div className="No_of_filled_forms">1/5 steps</div>
                </div>


                <div className="main_form_board">


                    <div className="account_create_form">

                        <div className="forms_category">     
                        <div className="logo_form"><img src="createacoountlistyourself.svg" alt =""/></div>
                        <div className="form_about">
                            <div className="form_headline">Create your account</div>
                            <div className="form_subheadline">Create your account</div>
                        </div>
                        </div>
                        
                        <div className="form_status_filled_icon"><img src ="./filled_icon.svg" alt  ="filled"/></div>
                      
                    </div>


                    <div className="store_form">  
                    <div className="forms_category"> 
                          <div className="logo_form"><img src="storerentyourself.svg" alt =""/></div>
                        <div className="form_about">
                            <div className="form_headline">Add your Store Details</div>
                            <div className="form_subheadline">Quickly add the relevant store details</div>
                        </div>
                        </div>
                        {isRegisterStorePresent ? (
                               <div className="form_status_filled_icon"><img src ="./filled_icon.svg" alt  ="filled"/></div>
                               ) : (
                        <Link  className ="Link" to = "Form1"> <div className="form_status">Add Details</div></Link>
                    )}
                    </div>
                         




                    <div className="shelf_form">  
                    <div className="forms_category"> 
                          <div className="logo_form"> <img src="shelf_add.svg" alt =""/></div>
                        <div className="form_about">
                            <div className="form_headline">Add shelves in your store </div>
                            <div className="form_subheadline">Add the details of the shelves you want to rent on the platform</div>
                        </div>
                        </div>
                        <Link  className ="Link" to = "Form2"><div className="form_status">Add Details</div></Link>
                        
                        </div>


                    <div className="bank_form">  
                    <div className="forms_category">   <div className="logo_form"><img src="Banklistyourself.svg" alt =""/></div>
                        <div className="form_about">
                            <div className="form_headline">Add Bank details</div>
                            <div className="form_subheadline">Add the details for a better </div>
                        </div>
                        </div>
                        <div className="form_status">Add Details</div></div>
                </div>


                </div>    
           
        </div>
   
    </div>
  )
}

export default Getting_started_store

