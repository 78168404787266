import React from 'react'
import "./aboutgoal.scss"
const aboutgoal = () => {

          return (
                <div className="aboutgoalourMission">

                                    <div className="aboutgoalsectionHeading">
                                        <div className="aboutgoalourMission1">
                                           <span>Our</span>
                                           <span className="aboutgoalmission"> mission</span>
                                        </div>
                                        <div className="aboutgoalourMissionIs1">Our mission is to foster a symbiotic relationship between eCommerce brands and brick-and-mortar stores, ensuring that the end consumer is served effectively. We strive to create an environment where both ends of the business consistently thrive amidst intense competition.</div>
                                    </div>



                        <div className="aboutgoalvalueProps">


                                  <div className="aboutgoalrectangleParent">
                                  <div className="aboutgoaldiv1">01</div>
                                  <img className="aboutgoalshoppingcartIcon" alt="" src="aboutgoalshoppingbagopenIcon.svg"  />
                                  <div className="aboutgoalsynergyInRetailContainer">
                                    Synergy in retail
                                  </div>
                                  </div>


                                  <div className="aboutgoalrectangleParent">
                                  <div className="aboutgoaldiv1">02</div>
                                  <img className="aboutgoalshoppingcartIcon" alt="" src="aboutgoalshoppingcartIcon.svg" />
                              
                                        <div className="aboutgoalsynergyInRetailContainer">Convenience for shoppers</div>
   
                       
                                  </div>


                                  <div className="aboutgoalrectangleParent">
                                  <div className="aboutgoalparent">
                                        <div className="aboutgoaldiv1">03</div>
                                        <img className="aboutgoalshoppingcartIcon" alt="" src="Factory.svg" />
                                        <div className="aboutgoalsynergyInRetailContainer_extra">Reduce wastage in ecosystem</div>
                                       
                                  </div>
                                  </div>


                         </div>
        </div>
                );
          }
export default aboutgoal 
