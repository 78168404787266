import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';

const Notification = () => {
  const [isActive, setIsActive] = useState(true);

  const handleToggle = () => {
    setIsActive(!isActive);
    const message = isActive ? 'Inactive Notification' : 'Active Notification';
    toast(message);
  };

  return (
    <div  className='notification_container_ecomm'>
        <div className="notification_sections">
<div className="notification_head">Notification</div>
<div className="notification_type">Shelf Booking/Cancellations</div>
<div className="notification_mediam">Email Notification</div>
<div className="notification_status">
      <img
        src={isActive ? "./ecomm_status_not.svg" : "./close_notifi.svg"}
        alt={isActive ? "Active" : "Inactive"}
        onClick={handleToggle}
        style={{ cursor: 'pointer' }}
      />
      <span>{isActive ? "Active" : "Inactive"}</span>
 
    </div>

        </div>
    
    </div>
  )
}

export default Notification
