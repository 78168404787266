
import React from 'react';
import "./ImagePlayground.css"

export const ImagePlayground = () => {
    return (
        <section className="image-playground">

            <div className="image-left">
                <div className="hero-image-checked-yellow">
                    <img src="./hero-section/checked-yellow.svg" alt="checked yellow"/>
                </div>

                <div className="hero-image-rating">
                    4.0
                </div>

                <div className="hero-image-rent-data">
                        <div className="rent-per-day-text">
                            Starts from
                        </div>
                        <div className="rent-per-day-price">
                            £ 15 / day
                        </div>
                </div>
            </div>

            
            <div className="image-right">
                <div className="hero-image-open-bag-green">
                    <img src="./hero-section/open-bag-green.svg" alt="open bag green"/>
                </div>

                <div className="hero-business">
                    <div className="hero-image-business-type">
                        <img src="./hero-section/business-fashion.svg" alt="business"/>
                        <div className="business-type">
                            Fashion
                        </div>
                    </div>

                    <div className="hero-image-product">
                        <img src="./hero-section/product-shoe.svg" alt="product shoe"/>
                        <div className="product-name">
                            Shoes
                        </div>
                    </div>
                </div>

                <div className="hero-demography">
                    <img src="./hero-section/demography.svg" alt="demography"/>
                    <div className="hero-demogrphy-details">
                        <div className="hero-demogrpahy-age" id="hero-demography-age">
                            18-30
                        </div>
                        <div className="text-highlight">
                            Customer demography
                        </div>
                    </div>
                </div>

            </div>

            <div className="btn btn-dark rent-now-btn">
            <a href="/Synergy" className="rent_now_img_playground" style={{ textDecoration: 'none',  color :'white'}}>Rent now</a>

            </div>
        </section>
    )
}