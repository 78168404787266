import React, { useState } from 'react'
import General from './General';
import Notification from './Notification';
import Privacy from './Privacy';

const Profile = () => {
  const [activeSection, setActiveSection] = useState('general');
  const renderSection = () => {
    switch (activeSection) {
    
      case 'general':
        return <General/>;
      case 'notification':
        return <Notification />;
      case 'privacy':
        return <Privacy />;
  
    
      default:
        return <General/>;
    }
  };
  return (
    <div className='profile_container'>
      <div className="profile_section">
           <div className="tab general" onClick={() => setActiveSection('general')}>General</div>
    <div className="tab notification" onClick={() => setActiveSection('notification')}>Notification</div>
    <div className="tab privacy" onClick={() => setActiveSection('privacy')}>Privacy</div>
           </div>

           <div className="data_section_profile">   {renderSection()}</div>
    </div>
  )
}

export default Profile

